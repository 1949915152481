export class ArtGalleryCollection {
    id: string;
    medias: Array<string>;
    medias_thumbnails: Array<string>;
    media_title: object;
    media_description: object;
    subcategory: string;
    size: string | undefined;
    year: string | undefined;
    medium: object | undefined;
    group: object | undefined;
    draw: Function | undefined;
    animate: boolean | undefined;
    
    constructor(
            id: string,
            medias: Array<string>, medias_thumbnails: Array<string>, media_title: object, media_description: object, subcategory: string, 
            size: string | undefined, year: string | undefined, medium: object | undefined, group: object | undefined,
            draw: Function | undefined, animate: boolean | undefined
        ) {
        this.id=id;
        this.medias = medias;
        this.medias_thumbnails = medias_thumbnails;
        this.media_title = media_title;
        this.media_description = media_description;
        this.subcategory = subcategory;
        this.size = size;
        this.year = year;
        this.medium = medium;
        this.group = group;
        this.draw = draw;
        this.animate = animate;
    }

    get_translated_title(language: string) {
        if (!(language in this.media_title)) language = "en";
        
        return this.media_title[language];
    }

    get_translated_description(language: string) {
        if (!(language in this.media_description)) language = "en";
        
        return this.media_description[language];
    }

    get_translated_medium(language: string) {
        if (this.medium != undefined) {
            if (!(language in this.medium)) language = "en";
        
            return this.medium[language];
        }
        else return null;
    }

    get_translated_group(language: string) {
        if (this.group != undefined) {
            if (!(language in this.group)) language = "en";
        
            return this.group[language];
        }
        else return null;
    }
}