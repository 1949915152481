import { Link } from "react-router-dom";

const itTranslations = {
    "home.title": "Benvenuto nel mio spazio digitale!",
    "home.content": 
    (
        <p>
            Sono Sara Sisti, una <strong>Sviluppatrice Freelance di software & AI e Artista</strong>, con una mente curiosa e creativa. Amo esplorare l'intersezione tra arte e tecnologia. 
            <br></br><br></br> 
            <strong>Tutto quello che vedi su questo sito (incluso il sito stesso) è stato sviluppato da me</strong>, è un regalo che ho deciso di fare a me stessa: uno spazio dove collezionare e <strong>condividere i miei esperimenti col mondo</strong>. 
            <br></br><br></br> 
            <strong>Vorresti collaborare o avere più informazioni sul mio lavoro?</strong> 
                <a href="mailto:sarasisti.mi@gmail.com" target="_blank" rel="noopener noreferrer" title="mail"> Contattami </a> o 
                <Link to="/contact"> seguimi sui social </Link> per rimanere aggiornato sui miei ultimi progetti e idee. <br></br>
            Per una panoramica delle mie competenze tecniche in Software & ML, esplora la sezione <Link to="/tech">Tech</Link>. <br></br>
            Per immergerti nella mia produzione artistica, invece, visita l'area <Link to="/art">Arte</Link>. <br></br>
            Trovi inoltre una breve descrizione su di me nella pagina <Link to="/about">About</Link>. <br></br>
        </p>
    ),
    "about.title": "About",
    "about.content": 
    (
        <p>
            Sono Sara Sisti, <strong>Sviluppatrice ed Artista</strong> di <strong>Milano</strong>. <strong>Segno zodiacale ariete (con luna in pesci e ascendente in acquario)</strong>. Mi considero una persona eclettica, con una mente curiosa, creativa, sempre affamata di nuove esperienze e conoscenze.
            <br></br><br></br>
            Mi sono laureata (triennale e magistrale) in <strong>Statistica e Data Science</strong> presso l'Università di Milano-Bicocca. È stato durante questo periodo che sono stata introdotta per la prima volta al mondo della programmazione e del machine learning, e mi ci sono buttata a capofitto, principalmente spinta dalla curiosità intellettuale. Dal 2019, ho lavorato come <strong>Sviluppatrice di Software e AI</strong> per diverse aziende. A settembre 2023, sono diventata <strong>freelance</strong>, continuando nello stesso ruolo.
            <br></br><br></br>
            Ho sempre amato l'<strong>Arte in tutte le sue forme</strong>: mi piace ammirarla nei musei e adoro sperimentare con <strong>pittura, ceramica, arte digitale, creative coding e arte generativa</strong>.
            <br></br><br></br>
            <strong>Amo leggere</strong>: sia narrativa che saggistica, le mie fisse più recenti sono la neuroscienza e la micologia. Autentica overthinker e daydreamer, mi incuriosisce molto il mondo onirico sia da sveglia che da addormentata. Oltre ad avere interessi variegati, mi piacciono gli spazi liminali tra le discipline: vorrei esplorare soprattutto l'<strong>intersezione tra arte e tecnologia</strong>.
        </p>
    ),
    "writing.title": "Writing",
    "writing.content": "Writing content it",
    "contact.title": "Contatti",
    "contact.content": "Puoi contattarmi (o seguirmi) tramite i seguenti canali:",
    "share.title": "Share",
    "copied": "Copiato!",
    "view.cv": "Leggi CV",
    "tech.education.thesis": "Tesi:",
    "tech.education.grade": "Voto:",
    "tech.project.by": "di",
    "navbar.contact": "Contatti",
    "gallery.subtitle.collections": "Collezioni",
    "gallery.subtitle.items": "Opere",
}
  
export default itTranslations;