export class Section {
    route: string;
    name: object;
    
    constructor(route: string, name: object) {
        this.route = route;
        this.name = name;
    }

    get_translated_name(language: string) {
        if (!(language in this.name)) language = "en";
        
        return this.name[language];
    }
}