import React from "react";
import { AppContext } from "../components/context/AppContext";
import get_translated_content from "../domain/languages/get_translated_content";
import { DEFAULT_CATEGORY } from "../domain/enums/DefaultCategory";
import TechSubCategories from "../domain/TechSubCategories";
import { ReactComponent as ArrowRightIcon } from "../domain/icons/icons8-arrow-32-right.svg";

class Tech extends React.Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
  
      this.state = {
        hiddenSections: Array(TechSubCategories.length).fill(false),
      };
    }

    toggleSection = (index) => {
      console.log("click")
      console.log(index)
      this.setState((prevState) => {
        const hiddenSections = [...prevState.hiddenSections];
        hiddenSections[index] = !hiddenSections[index];
        return { hiddenSections };
      });
      console.log(this.state)
    };

    render() {
      const { language } = this.context;
      const { isGlitchActive } = this.props;

      return TechSubCategories.map((cat, index) => (
        <div className={`tech ${isGlitchActive ? 'glitch' : ''}`} id={`tech${index}`} key={index}>
          <p className="font-face-sometypemono page-title">
            {cat.get_translated_title(language)}
            {cat.title !== DEFAULT_CATEGORY && (<ArrowRightIcon
              className={`arrow-right-icon art-section-close ${
                this.state.hiddenSections[index] ? "" : "rotated"
              }`}
              onClick={() => this.toggleSection(index)}
            />)}
          </p>
          {!this.state.hiddenSections[index] && 
          <div className={`font-face-forosans page-paragraph ${this.state.hiddenSections[index] ? "hidden" : ""}`}>
            {cat.get_translated_content(language)}
          </div>}
        </div>
      ));
    }
  }

export default Tech;