import React from 'react';
import get_translated_content from '../../domain/languages/get_translated_content';

const TechProjectComponent = ({ el, language }) => {
  return (
    <div className="tech-project tech-subcategory">
        <div className='tech-timeline'></div>
        <div className='tech-timeline-point'></div>
        <div className='tech-subcategory-left'>
            <strong>{el.when} <br></br>
            <a href={el.link} target="_blank" rel="noreferrer">{el.get_translated_title(language)} </a></strong> 
            {get_translated_content(language, "tech.project.by")} {el.collaborators} <br></br>
        </div>
        <div className='tech-subcategory-right'>
            <span>{el.get_translated_description(language)}</span>
        </div>
    </div>
  );
};

export default TechProjectComponent;
