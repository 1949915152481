import React from "react";
import PageContent  from "../components/PageContent";
import { ReactComponent as GithubIcon } from "../domain/icons/iconmonstr-github-1.svg";
import { ReactComponent as LinkedinIcon } from "../domain/icons/iconmonstr-linkedin-3.svg";
import { ReactComponent as InstagramIcon } from "../domain/icons/iconmonstr-instagram-11.svg";
import { ReactComponent as MailIcon } from "../domain/icons/mail-142.svg";
import { ReactComponent as TwitterIcon } from "../domain/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../domain/icons/youtube.svg";
import { ReactComponent as GoodReadsIcon } from "../domain/icons/goodreads.svg";
import { ReactComponent as MediumIcon } from "../domain/icons/medium-icon.svg";
import { AppContext } from "../components/context/AppContext";
import get_translated_content from "../domain/languages/get_translated_content";
import flowers_img from "../domain/images/art/flowers_eyes.png";


class Contact extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { language } = this.context;
    const { isGlitchActive } = this.props;

    const img = <img src={flowers_img}></img>

    let content = (
      <div className={`contact ${isGlitchActive ? 'glitch' : ''}`}>
        <div className="font-face-sometypemono page-title">
        {get_translated_content(language, "contact.title")}</div>
        <div className="font-face-forosans page-paragraph">
        <p>
          {get_translated_content(language, "contact.content")}
        </p>
        <div className="social-icons-container">
          <div>
            <a href="mailto:sarasisti.mi@gmail.com" target="_blank" rel="noopener noreferrer" title="mail">
              <div className="social">
                <MailIcon /> 
                <p>sarasisti.mi@gmail.com</p>
              </div>
            </a>
          </div>  
          <div>
            <a href="https://github.com/sarasixti" target="_blank" rel="noopener noreferrer" className="social-icon" title="github">
              <div className="social">
                <GithubIcon /> 
                <p>sarasixti</p>
              </div>
            </a>  
          </div>
          <div>
            <a href="https://www.linkedin.com/in/sara-sisti-b169a1141/" target="_blank" rel="noopener noreferrer" className="social-icon" title="linkedin">
              <div className="social">
                <LinkedinIcon /> 
                <p>Sara Sisti</p>
              </div>
            </a>  
          </div>
          <div>
            <a href="https://medium.com/@sarasisti.mi" target="_blank" rel="noopener noreferrer" className="social-icon" title="medium">
              <div className="social">
                <MediumIcon /> 
                <p>Sara Sisti</p>
              </div>
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/sarasixti/" target="_blank" rel="noopener noreferrer" className="social-icon" title="instagram">
              <div className="social">
                <InstagramIcon /> 
                <p>sarasixti (personal profile)</p>
              </div>
            </a>
            <a href="https://www.instagram.com/_rebelot/" target="_blank" rel="noopener noreferrer" className="social-icon" title="instagram">
              <div className="social">
                <InstagramIcon /> 
                <p>_rebelot (art profile)</p>
              </div>
            </a> 
          </div>
          <div>
            <a href="https://twitter.com/sarasixti" target="_blank" rel="noopener noreferrer" className="social-icon" title="twitter">
              <div className="social">
                <TwitterIcon /> 
                <p>sarasixti</p>
              </div>
            </a>
          </div>
          <div>
            <a href="https://youtube.com/@sarasisti9127" target="_blank" className="social-icon" rel="noopener noreferrer" title="youtube">
              <div className="social">
                <YoutubeIcon /> 
                <p>Sara Sisti</p>
              </div>
            </a>
          </div>
          <div>
            <a href="https://www.goodreads.com/user/show/104640120-sarasixti" target="_blank" rel="noopener noreferrer" className="social-icon" title="goodreads">
              <div className="social">
                <GoodReadsIcon /> 
                <p>Sara Sisti</p>
              </div>
            </a>
          </div>
        </div> 
        </div>
      </div>
    );

    return <PageContent id="contact" content={content} img={img}/>;
  }
}

export default Contact;
