import me1 from "../images/about_me_images/io1.png";
import me2 from "../images/about_me_images/io2.png";
import me3 from "../images/about_me_images/io3.png";
import me4 from "../images/about_me_images/io4.png";
import me5 from "../images/about_me_images/io5.png";
import me6 from "../images/about_me_images/io6.png";
import me7 from "../images/about_me_images/io7.png";
import me8 from "../images/about_me_images/io8.png";
const random = require('canvas-sketch-util/random');

const drawRandomImages = ({ context, canvas, width, height, frame }) => {
    const images_src =  [me1, me2, me3, me4, me5, me6, me7, me8];
    const images_start = [];
    let images = []; // array to store the image objects

    if (frame === 0) {
        // Initialize the canvas on the first frame
        context.fillStyle = "transparent";
        context.fillRect(0, 0, width, height);

        for (let i = 0; i < 8; i++) {
         const image = new Image();
         image.src = images_src[i]; // Replace with your image URLs
         images_start.push(image);
         images.push(image);
       }
    }

    let frames = [];
  
    return ({ context, canvas, width, height, frame }) => {
  
      // Update canvas on every frame
      if ((frame - 10) % 40 === 0) {

        if (frames.includes(frame)) return;
        frames.push(frame);

        if (images.length == 0) images = images_start;
        
        let image;
        if (images.length == 1) image = images[0]
        else image = random.pick(images);

        images = images.filter(item => item !== image);
  
        const randomScale = random.range(0.3, 0.8); 

        const minAngle = -180;
        const maxAngle = 180;
        const randomRotation = random.value() * (maxAngle - minAngle) + minAngle; // Random rotation between -45 and 45 degrees
  
        const imgWidth = image.width * randomScale;
        const imgHeight = image.height * randomScale;

        // Calculate the minimum and maximum allowed translation in the X and Y directions
        const minX = imgWidth / 2;
        const minY = imgHeight / 2;     
        const maxX = width - imgWidth / 2;
        const maxY = height - imgHeight / 2;

        const randomX = random.value() * (maxX - minX) + minX; // Random X position
        const randomY = random.value() * (maxY - minY) + minY; // Random Y position
    
        context.save();
        context.translate(randomX, randomY);
        context.rotate(randomRotation * (Math.PI / 180));
        context.drawImage(image, -imgWidth / 2, -imgHeight / 2, imgWidth, imgHeight);
        context.restore();
      }
    };
  };
  

export default drawRandomImages;