import React from "react";
import { ReactComponent as HamburgerMenuIcon } from "../domain/icons/hamburger-menu.svg";
import { Link } from "react-router-dom";
import DropDown from "./navbar/DropDown";
import ArtSubCategories from "../domain/ArtSubCategories";
import TechSubCategories from "../domain/TechSubCategories";
import { ReactComponent as ShareIcon } from "../domain/icons/icons8-share.svg";
import Share from "./Share";
import LanguageSelector from "./LanguageSelector";
import get_translated_content from "../domain/languages/get_translated_content";
import { AppContext } from "./context/AppContext";

class HamburgerMenu extends React.Component {
  static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = { showMenu: false, showShare: false, copied: false };
      this.toggleMenu = this.toggleMenu.bind(this);
      this.closeMenu = this.closeMenu.bind(this);
      this.closeShare = this.closeShare.bind(this);
    }

    toggleMenu() {
        this.setState({ showMenu: !this.state.showMenu });
    }

    closeMenu() {
        this.setState({ showMenu: false });
    }

    toggleShare = () => {
      this.setState({ showShare: !this.state.showShare });
    }

    closeShare() {
      this.setState({ showShare: false });
    }

    render() {
      const {language} = this.context;

      return (
        <div>
            <div>
                <HamburgerMenuIcon className="hamburger-menu-icon" onClick={this.toggleMenu}/>
            </div>
            { this.state.showMenu && 
                <div className="mobile-menu font-face-sometypemono ">
                    <p className="navelement" onClick={this.closeMenu}><Link to="/">Home</Link></p>
                    <p className="navelement" onClick={this.closeMenu}><Link to="/about">About</Link></p>
                    {/* <p className="navelement" onClick={this.closeMenu}><Link to="/art">Art</Link></p> */}
                    <DropDown title="Art" subcategories={ArtSubCategories} closeMenu={this.closeMenu}/> 
                    {/* <p className="navelement" onClick={this.closeMenu}><Link to="/tech">Tech</Link></p> */}
                    <DropDown title="Tech" subcategories={TechSubCategories} closeMenu={this.closeMenu}/> 
                    <p className="navelement" onClick={this.closeMenu}><Link to="/contact">{get_translated_content(language, "navbar.contact")}</Link></p>
                    {/* <ShareIcon className="share-icon navelement" onClick={this.toggleShare}/> */}
                    {this.state.showShare && <Share closeShare={this.closeShare} />}
                    <LanguageSelector className="navelement" closeMenu={this.closeMenu}></LanguageSelector>
                </div>}
        </div>
      );
    }
  }

export default HamburgerMenu;