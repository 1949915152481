import React from "react";
import { Link } from "react-router-dom";
import DropDown from "./DropDown";
import ArtSubCategories from "../../domain/ArtSubCategories";
import TechSubCategories from "../../domain/TechSubCategories";
import { ReactComponent as ShareIcon } from "../../domain/icons/icons8-share.svg";
import { ReactComponent as CopyIcon } from "../../domain/icons/copy-icon.svg";
import LanguageSelector from "../LanguageSelector";
import Share from "../Share";
import get_translated_content from "../../domain/languages/get_translated_content";
import { AppContext } from "../context/AppContext";
import { SectionsRoutes } from "../../domain/enums/SectionsRoutes.ts";

class NavBar extends React.Component {
  static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = { showShare: false };
      this.closeShare = this.closeShare.bind(this);
    }

    toggleShare = () => {
      this.setState({ showShare: !this.state.showShare });
    }

    closeShare() {
      this.setState({ showShare: false });
    }

    render() {
      const {language} = this.context;

      return (
        <div className="navbar font-face-sometypemono">
              <p className="navelement"><Link to={SectionsRoutes.HOME}>Home</Link></p>
              <p className="navelement"><Link to={SectionsRoutes.ABOUT}>About</Link></p>
              <DropDown title="Art" subcategories={ArtSubCategories} /> 
              <DropDown title="Tech" subcategories={TechSubCategories} /> 
              <p className="navelement"><Link to={SectionsRoutes.CONTACT}>{get_translated_content(language, "navbar.contact")}</Link></p>
              <ShareIcon className="share-icon navelement" onClick={this.toggleShare}/>
              <LanguageSelector className="navelement"></LanguageSelector>
              {this.state.showShare && <Share closeShare={this.closeShare} />}
        </div>
      );
    }
  }

export default NavBar;