import React, { useEffect, useRef } from "react";
import canvasSketch from "canvas-sketch";

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();

    const { draw, animate, width, height } = this.props;
    this.draw = draw;
    this.animate = animate;
    this.width = width;
    this.height = height;

    this.canvasInitialized = false; // Track if canvas has been initialized
  }

  componentDidMount() {
    if (!this.canvasInitialized) { // Run canvasSketch only once
      canvasSketch(this.draw, {
        dimensions: [this.width, this.height],
        units: "px",
        resizeCanvas: false,
        styleCanvas: true,
        scaleToView: false,
        canvas: this.canvasRef.current,
        animate: this.animate,
      });

      this.canvasInitialized = true; // Set canvasInitialized to true
    }
  }

  componentWillUnmount() {
    // Cleanup code if necessary
  }

  render() {
    return (
      <canvas
        id="canvas"
        ref={this.canvasRef}
        width={this.width}
        height={this.height}
        style={{ backgroundColor: "transparent" }}
      />
    );
  }
}

export default Canvas;
