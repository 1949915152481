import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { AppContext } from "./context/AppContext";

const ScrollToTop = (props) => {
  const context = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    const { isMobile } = context;
    let canScroll = true;

    if (!location.hash && !location.pathname.includes("/art/")) {
        if (location.state) {
            if (location.state.scroll == false)
            canScroll = false;
        }
      if (canScroll) window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;