import React from "react";
import { AppContext } from "./context/AppContext";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

class ResponsiveApp extends React.Component {
    static contextType = AppContext;

    render() {
        const { isMobile } = this.context;
        const { isGlitchActive, handleGlitchHover } = this.props;

        let content = (
        <div className="body-content">
            <div className="body-content-upper">
              <Header isGlitchActive={isGlitchActive} />
              <Main isGlitchActive={isGlitchActive} />
            </div>
            <Footer isGlitchActive={isGlitchActive} onGlitchHover={handleGlitchHover} />
        </div>
        )

        if (isMobile) {
            content = (<div className="mobile-container">
                <Header isGlitchActive={isGlitchActive} />
                <Main isGlitchActive={isGlitchActive} />
                <Footer isGlitchActive={isGlitchActive} onGlitchHover={handleGlitchHover} />
            </div>)
        }

        return content;
    }

}

export default ResponsiveApp;