export const compareDates = (date1, date2) => {
    const [day1, month1, year1] = date1.split('/').map(Number);
    const [day2, month2, year2] = date2.split('/').map(Number);
  
    // Compare years
    if (year1 !== year2) {
      return year2 - year1;
    }
  
    // Compare months
    if (month1 !== month2) {
      return month2 - month1;
    }
  
    // Compare days
    return day2 - day1;
  };