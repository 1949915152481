import React from "react";
import PageContent  from "../components/PageContent";
import { AppContext } from "../components/context/AppContext";
import get_translated_content from "../domain/languages/get_translated_content";
import eyes_mouse_tracker from "../domain/images/art/eyes_mouse_tracker.gif";
import { calculatePixelWidth } from "../utils/compute_vw";
import News from "../components/news/News";
import { Link } from "react-router-dom";

class HomePage extends React.Component {
  static contextType = AppContext;

    render() {
      const { language } = this.context;
      const { isMobile, isGlitchActive } = this.props;

      let canvaDim;
      if (isMobile) canvaDim = calculatePixelWidth(80);
      else canvaDim = 500;

      const img = <img src={eyes_mouse_tracker} alt="Eyes tracking mouse animation"></img>;

      let content = (
        <div className={`home ${isGlitchActive ? 'glitch' : ''}`}>
          <News />
          <div className="font-face-sometypemono page-title">{get_translated_content(language, "home.title")}</div>
          <div className="font-face-forosans page-paragraph">{get_translated_content(language, "home.content")}</div>
        </div>
      );

      return <PageContent id="home" content={content} img={img}/>;
    }
  }

export default HomePage;