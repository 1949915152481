export class NewsItem {
    date: string;
    title: object;
    description: object;
    
    constructor(date: string, title: object, description: object) {
        this.date = date;
        this.title = title;
        this.description = description;
    }

    get_translated_title(language: string) {
        if (!(language in this.title)) language = "en";
        
        return this.title[language];
    }

    get_translated_description(language: string) {
        if (!(language in this.description)) language = "en";
        
        return this.description[language];
    }
}