import { Link } from "react-router-dom";

const enTranslations = {
    "home.title": "Welcome to my digital space!",
    "home.content": 
    (
        <div>
            I'm Sara Sisti, a <strong>Freelance Software & AI Developer and Artist</strong>, with a curious and creative mind. I love exploring the intersection of art and technology. 
            <br></br><br></br> 
            <strong>Everything you see on this website (including the website itself) has been developed by me</strong>; it's a gift I've decided to give to myself—a space to collect and <strong>share my experiments with the world</strong>. 
            <br></br><br></br> 
            <strong>Interested in collaborating or curious to learn more about my work?</strong> 
                <a href="mailto:sarasisti.mi@gmail.com" target="_blank" rel="noopener noreferrer" title="mail"> Get in touch </a> or 
                 <Link to="/contact"> follow me on social media </Link> 
            to stay updated on my latest projects and insights.  <br></br>
            For an overview of my technical Software & ML skills, explore the <Link to="/tech">Tech</Link> section.  <br></br>
            To dive into my artistic production, visit the <Link to="/art">Art</Link> area.  <br></br> 
            You'll also find a brief description about me in the <Link to="/about">About</Link> page.  <br></br>
        </div>
    ),
    "about.title": "About",
    "about.content": 
    (
        <p>
            I'm Sara Sisti, <strong>Developer and Artist</strong> from <strong>Milan</strong>. I am <strong>an Aries (with Pisces moon and Aquarius rising)</strong>. I consider myself an eclectic person, with a curious and creative mind, always hungry for new experiences and knowledge. 
            <br></br><br></br> 
            I completed both my bachelor's and master's degrees in <strong>Statistics and Data Science</strong> at the University of Milan-Bicocca. That's when I was first exposed to the world of programming and machine learning, and I dived headfirst into it, mainly driven by intellectual curiosity. Since 2019, I've been working as a <strong>Software & AI Developer</strong> for various companies. In September 2023, I transitioned to <strong>freelancing</strong> while continuing in the same role. 
            <br></br><br></br> 
            I have always been in love with <strong>Art in all its forms</strong>: I enjoy admiring it in museums, and I love experimenting with <strong>painting, clay, digital art, creative programming, and generative art</strong>. 
            <br></br><br></br> 
            <strong>I love reading</strong>: both fiction and non-fiction, my recent fixations are neuroscience and mycology. Authentic overthinker and daydreamer, I am deeply intrigued by the dream world, both while awake and asleep. In addition to having diverse interests, I am drawn to the liminal spaces between disciplines. I am particularly interested in exploring the <strong>intersection of art and technology</strong>.
        </p>
    ),
    "writing.title": "Writing",
    "writing.content": "Writing content en",
    "contact.title": "Contacts",
    "contact.content": "You can reach me (or follow me) through the following channels:",
    "share.title": "Share",
    "copied": "Copied!",
    "view.cv": "Show CV",
    "tech.education.thesis": "Thesis:",
    "tech.education.grade": "Grade:",
    "tech.project.by": "by",
    "navbar.contact": "Contact",
    "gallery.subtitle.collections": "Collections",
    "gallery.subtitle.items": "Artworks",
}

export default enTranslations;
  