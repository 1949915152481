import React from "react";
import { Link } from "react-router-dom";
import title from "../domain/images/title.gif";

class Title extends React.Component {
    render() {
      return (
        <div className="title">
          <Link to="/">
          <img src={title} height="35px" className="img-title" alt="Sara Sisti"></img>
            {/* <h1 className="font-face-sometypemono">{this.props.title}</h1> */}
            <div>
              <marquee><h2 className="font-face-sometypemono subtitle">{this.props.subtitle}</h2></marquee>
            </div>
          </Link>
          
        </div>
      );
    }
  }

export default Title;