import { SubCategory } from "./models/SubCategory.ts";
import { ArtSubCategoryTitle } from "./enums/ArtSubCategoryTitle.ts";
import { SectionsRoutes } from "./enums/SectionsRoutes.ts";

const ArtSubCategories = [
    new SubCategory(ArtSubCategoryTitle.ALL, SectionsRoutes.ART, {"en": "Art", "es": "Arte", "it": "Arte"}, 
        {"en": "Art", "it": "Arte"},
        {"en": (
            <p>
                Creativity has always been my way of exploring new ideas freely. I like <strong>blending and integrating different media</strong> (painting, clay, digital art, creative coding, generative art) into my work, <strong>creating experimental compositions, mixing the physical and digital realms</strong>. My professional background in machine learning has motivated me to incorporate generative AI into my creative process. 
                <br></br><br></br>
                My favourite artistic style, and the one my experiments resemble the most, is <strong>surrealism</strong>: both in terms of the themes it explores and the creative process itself. I enjoy letting my art be guided by my subconscious, allowing <strong>free association of images and ideas to shape the final outcome</strong>.
                <br></br><br></br>
                The main source of my inspiration is the wonder evoked by seemingly common and taken-for-granted things, which actually conceal secrets and profound concepts. I am fascinated by the incredible complexity of the human mind, the mystery of consciousness, qualia, perception, and altered states of consciousness such as dreams, lucid dreams, hallucinations and psychoses. Each person contains an entire universe, filled with diverse experiences and encounters with people, places, books, and songs of which we have no inkling. I often reflect on the incredibly vast diversity of life on Earth. The absurdity of everyday reality and existence itself will never cease to amaze and amuse me.
            </p>
        ), 
        "it": (
            <p>
                La creatività è sempre stata il mio modo di esplorare nuove idee liberamente. Mi piace <strong>mescolare e integrare diversi media</strong> (pittura, ceramica, arte digitale, creative coding, arte generativa) nel mio lavoro, <strong>creando composizioni sperimentali, mescolando i regni fisici e digitali</strong>. Il mio background professionale in machine learning mi ha motivato a incorporare l'AI generativa nel mio processo creativo.
                <br></br><br></br>
                Il mio stile artistico preferito, e quello a cui assomigliano di più i miei esperimenti, è il <strong>surrealismo</strong>: sia in termini di temi che esplora, sia nel processo creativo stesso. Mi piace lasciare che la mia arte sia guidata dal mio subconscio, permettendo alla <strong>libera associazione di immagini e idee di plasmare il risultato finale</strong>.
                <br></br><br></br>
                La principale fonte della mia ispirazione è lo stupore evocato da cose apparentemente comuni e date per scontate, che in realtà celano segreti e concetti profondi. Sono affascinato dall'incredibile complessità della mente umana, dal mistero della coscienza, della qualia, della percezione e degli stati alterati di coscienza come sogni, sogni lucidi, allucinazioni e psicosi. Ogni persona contiene un intero universo, pieno di esperienze diverse e incontri con persone, luoghi, libri e canzoni di cui non abbiamo la minima idea. Rifletto spesso sull'incredibile vastità della diversità della vita sulla Terra. L'assurdità della realtà quotidiana e dell'esistenza stessa non smetterà mai di stupirmi e divertirmi.            
            </p>
        )}),
    new SubCategory(ArtSubCategoryTitle.PAINTINGS, "/art", {"en": "Paintings", "it": "Dipinti"}, 
        {"en": "Paintings & Sketches", "it": "Dipinti & Schizzi"}, {"en": null, "it": null}),
    new SubCategory(ArtSubCategoryTitle.DIGITAL, "/art", {"en": "Digital Art", "it": "Arte Digitale"}, 
        {"en": "Digital & Generative Art, Creative Coding", "it": "Arte Digitale & Generativa, Coding Creativo"}, {"en": null, "it": null}),
    new SubCategory(ArtSubCategoryTitle.CERAMICS, "/art", {"en": "Ceramics", "it": "Ceramica"}, 
        {"en": "Ceramics & Pottery", "it": "Ceramica & Argilla"}, {"en": null, "it": null})
];

export default ArtSubCategories;