import React from "react";
import Gallery from "../components/gallery/Gallery";
import { ArtGalleryItems } from "../domain/ArtGalleryItems";
import { ArtGalleryCollections } from "../domain/ArtGalleryCollections";
import { AppContext } from "../components/context/AppContext";
import get_translated_content from "../domain/languages/get_translated_content";
import ArtSubCategories from "../domain/ArtSubCategories";
import { DEFAULT_CATEGORY } from "../domain/enums/DefaultCategory";
import { calculatePixelWidth } from "../utils/compute_vw";
import { Outlet } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../domain/icons/icons8-arrow-32-right.svg";

class Art extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      hiddenSections: Array(ArtSubCategories.length).fill(false),
    };
  }

  toggleSection = (index) => {
    console.log("click")
    console.log(index)
    this.setState((prevState) => {
      const hiddenSections = [...prevState.hiddenSections];
      hiddenSections[index] = !hiddenSections[index];
      return { hiddenSections };
    });
    console.log(this.state)
  };

  render() {
    const { language, isMobile } = this.context;
    const { isGlitchActive } = this.props;

    let thumbnalSize;
    if (isMobile) thumbnalSize = 150;
    else thumbnalSize = 200;

    return (
      <div>
        {ArtSubCategories.map((cat, index) => (
          <div
            className={`art ${isGlitchActive ? "glitch" : ""} `}
            id={`art${index}`}
            key={index}
          >
            <p className="font-face-sometypemono page-title">
              {cat.get_translated_subtitle(language)}
              {cat.title !== DEFAULT_CATEGORY && (
                <ArrowRightIcon
                  className={`arrow-right-icon art-section-close ${
                    this.state.hiddenSections[index] ? "" : "rotated"
                  }`}
                  onClick={() => this.toggleSection(index)}
                />
              )}
            </p>
            {!this.state.hiddenSections[index] && (
              <div className={`font-face-forosans page-paragraph ${this.state.hiddenSections[index] ? "hidden" : ""}`}>
                {cat.get_translated_content(language)}
              </div>
            )}
            {!this.state.hiddenSections[index] && cat.title !== DEFAULT_CATEGORY && (
              <div className={`page-paragraph ${this.state.hiddenSections[index] ? "hidden" : ""}`} >
                <Gallery
                  items={ArtGalleryItems}
                  collections={ArtGalleryCollections}
                  thumbnail_size={thumbnalSize}
                  subcategory={cat.title}
                />
              </div>
            )}
          </div>
        ))}
        <Outlet />
      </div>
    );
  }
}

export default Art;
