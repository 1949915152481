import { Link } from "react-router-dom";
import { ArtGalleryItem } from "./models/ArtGalleryItem.ts";
import flowers_eyes from "./images/art/flowers_eyes.png";
import mush_skull from "./images/art/mush_skull.png";
import trilli_palace from "./images/art/trilli_palace.png";
import embrace_confusion from "./images/art/embrace_confusion.png";
import eyes_mouse_tracker from "./images/art/eyes_mouse_tracker.gif";
import flower_space from "./images/art/flower_space.png";
import high_eye from "./images/art/high_eye.png";
import mask_mirrored from "./images/art/mask_mirrored.png";
import show_them from "./images/art/show_them_what_youre_made_of.png";
import snail_ufo from "./images/art/snail_ufo.png";
import ufo_desert from "./images/art/ufo_desert.png";
import wild_side from "./images/art/walk_on_the_wild_side.png";
import am_id_dreaming from "./images/art/Am_i_dreaming.png";
import azulejo from "./images/art/azulejo.png";
import flower_eyes_2 from "./images/art/flowers_eyes_2.png";
import eyes from "./images/art/eyes.png";
import shared_thoughts from "./images/art/shared_thoughts.png";
import wanderer from "./images/art/Wanderer.png";
import consciosness_memory_anticipation from "./images/art/consciousness_memory_anticipation.png";
import wolkswagen from "./images/art/wolkswagen.png";
import vase1 from "./images/art/vase1.png";
import vase2 from "./images/art/vase2.png";
import mask from "./images/art/mask.png";
import vase3 from "./images/art/vase3.png";
import vase4 from "./images/art/vase4.png";
import vase5 from "./images/art/vase5.png";
import vase6 from "./images/art/vase6.png";
import vase7 from "./images/art/vase7.png";
import vase8 from "./images/art/vase8.png";
import vase9 from "./images/art/vase9.png";
import vase10 from "./images/art/vase10.png";
import vase11 from "./images/art/vase11.png";
import solipsism from "./images/art/solipsism.png";
import bauhaus_1 from "./images/art/bauhaus-1.gif";
import bauhaus_2 from "./images/art/bauhaus-2.gif";
import coffee from "./images/art/coffee.png";
import mushrooms from "./images/art/mushrooms.png";
import natura_morta from "./images/art/natura_morta.png";
import astronaut from "./images/art/astronaut.png";
import monnalisa from "./images/art/monnalisa.png";
import lebron from "./images/art/lebron.png";
import read_real from "./images/art/read_real.png";
import table from "./images/art/table.png";
import lets_get_high from "./images/art/lets_get_high.png";
import plants from "./images/art/plants.png";
import melt_face from "./images/art/melt_face.png";
import sun_mountains from "./images/art/sun_mountains.png";
import stay_with_me_a_while from "./images/art/stay_with_me_a_while.png";
import thumbnail_flowers_eyes from "./images/art_thumbnails/flowers_eyes.png";
import thumbnail_mush_skull from "./images/art_thumbnails/mush_skull.png";
import thumbnail_trilli_palace from "./images/art_thumbnails/trilli_palace.png";
import thumbnail_embrace_confusion from "./images/art_thumbnails/embrace_confusion.png";
import thumbnail_eyes_mouse_tracker from "./images/art_thumbnails/eyes_mouse_tracker.gif";
import thumbnail_flower_space from "./images/art_thumbnails/flower_space.png";
import thumbnail_high_eye from "./images/art_thumbnails/high_eye.png";
import thumbnail_mask_mirrored from "./images/art_thumbnails/mask_mirrored.png";
import thumbnail_show_them from "./images/art_thumbnails/show_them_what_youre_made_of.png";
import thumbnail_snail_ufo from "./images/art_thumbnails/snail_ufo.png";
import thumbnail_ufo_desert from "./images/art_thumbnails/ufo_desert.png";
import thumbnail_wild_side from "./images/art_thumbnails/walk_on_the_wild_side.png";
import thumbnail_am_id_dreaming from "./images/art_thumbnails/Am_i_dreaming.png";
import thumbnail_azulejo from "./images/art_thumbnails/azulejo.png";
import thumbnail_flower_eyes_2 from "./images/art_thumbnails/flowers_eyes_2.png";
import thumbnail_eyes from "./images/art_thumbnails/eyes.png";
import thumbnail_shared_thoughts from "./images/art_thumbnails/shared_thoughts.png";
import thumbnail_wanderer from "./images/art_thumbnails/Wanderer.png";
import thumbnail_consciousness_memory_anticipation from "./images/art_thumbnails/consciousness_memory_anticipation.png";
import thumbnail_wolkswagen from "./images/art_thumbnails/wolkswagen.png";
import thumbnail_vase1 from "./images/art_thumbnails/vase1.png";
import thumbnail_vase2 from "./images/art_thumbnails/vase2.png";
import thumbnail_mask from "./images/art_thumbnails/mask.png";
import thumbnail_vase3 from "./images/art_thumbnails/vase3.png";
import thumbnail_vase4 from "./images/art_thumbnails/vase4.png";
import thumbnail_vase5 from "./images/art_thumbnails/vase5.png";
import thumbnail_vase6 from "./images/art_thumbnails/vase6.png";
import thumbnail_vase7 from "./images/art_thumbnails/vase7.png";
import thumbnail_vase8 from "./images/art_thumbnails/vase8.png";
import thumbnail_vase9 from "./images/art_thumbnails/vase9.png";
import thumbnail_vase10 from "./images/art_thumbnails/vase10.png";
import thumbnail_vase11 from "./images/art_thumbnails/vase11.png";
import thumbnail_solipsism from "./images/art_thumbnails/solipsism.png";
import thumbnail_bauhaus_1 from "./images/art_thumbnails/bauhaus-1.gif";
import thumbnail_bauhaus_2 from "./images/art_thumbnails/bauhaus-2.gif";
import thumbnail_coffee from "./images/art_thumbnails/coffee.png";
import thumbnail_mushrooms from "./images/art_thumbnails/mushrooms.png";
import thumbnail_natura_morta from "./images/art_thumbnails/natura_morta.png";
import thumbnail_astronaut from "./images/art_thumbnails/astronaut.png";
import thumbnail_monnalisa from "./images/art_thumbnails/monnalisa.png";
import thumbnail_lebron from "./images/art_thumbnails/lebron.png";
import thumbnail_read_real from "./images/art_thumbnails/read_real.png";
import thumbnail_table from "./images/art_thumbnails/table.png";
import thumbnail_lets_get_high from "./images/art_thumbnails/lets_get_high.png";
import thumbnail_plants from "./images/art_thumbnails/plants.png";
import thumbnail_melt_face from "./images/art_thumbnails/melt_face.png";
import thumbnail_sun_mountains from "./images/art_thumbnails/sun_mountains.png";
import thumbnail_stay_with_me_a_while from "./images/art_thumbnails/stay_with_me_a_while.png";
import turn_on_the_lights from "./images/art/turn_on_the_lights.mp4";
import infinite_zoom_flower_eyes_1 from "./images/art/infinite_zoom_flower_eyes_1.mp4";
import touchdesigner1 from "./images/art/touchdesigner1.mp4";
import flowers_eyes_3 from "./images/art/flowers_eyes_3.png";
import pisque_clay from "./images/art/pisque_clay.png";
import floating_city from "./images/art/floating_city.png";
import woman_with_mantilla from "./images/art/woman_with_mantilla.png";
import infinite_zoom_2 from "./images/art/infinite_zoom_2.mp4";


export const ArtGalleryItems = [
    new ArtGalleryItem(
      "1",
      embrace_confusion, thumbnail_embrace_confusion,
      {"en": "Embrace Confusion", "it": "Accogli la Confusione"}, 
      {
        "en": "This digital artwork has been created with Procreate. The mantra 'embrace confusion' is for encouraging us to accept and even welcome moments of uncertainty and complexity as opportunities for growth and learning.",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. Il mantra 'embrace confusion' ('abbraccia la confusione') è per incoraggiarci ad accettare e addirittura accogliere i momenti di incertezza e complessità come opportunità di crescita e apprendimento."
      },
      "Digital Art", "1024px x 1024px", "2021",   
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "2",
      eyes_mouse_tracker, thumbnail_eyes_mouse_tracker,
      {"en": "They're watching you", "it": "Ti stanno guardando"}, 
      {
        "en": "This artwork is made with pure code. It has been coded with javascript (specifically, using the canvas-sketch js library). This is just a screen-recording turned into a gif, but the real artwork consists in a mouse tracker that is followed by all the eyes around it.",
        "it": "Questa opera è stata realizzata con solo codice. È stata sviluppata con JavaScript (nello specifico, utilizzando la libreria canvas-sketch). Questa è solamente una registrazione della schermata trasformata in gif, ma l'opera d'arte reale consiste in un tracker del mouse seguito dai numerosi gli occhi intorno."
      },
      "Digital Art", "2048px x 2048px", "2023",   
      {"en": "Javascript", "it": "Javascript"}, 
      {"en": "Creative Coding", "it": "Creative Coding"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "3",
      flower_space, thumbnail_flower_space,
      {"en": "Blooming in space", "it": "Fiorire nello spazio"}, 
      {
        "en": "This digital artwork has been created with Procreate. It was inspired by the idea of plant consciousness (the eye, in fact, represent being conscious). This is a theme that I'm fond of and I've created different versions of it.",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. È stato ispirato dall'idea della coscienza delle piante (l'occhio, infatti, rappresenta l'essere coscienti). Questo è un tema a cui sono affezionata e ne ho creato diverse versioni."
      },
      "Digital Art", "958px x 958px", "2020",   
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "4",
      high_eye, thumbnail_high_eye,
      {"en": "Higher than ever", "it": "Più in alto di sempre"}, 
      {
        "en": "This digital artwork has been created with Procreate. The background is inspired by mandalas: geometric configurations of symbols. In various spiritual traditions, mandalas may be employed for focusing attention of practitioners and adepts, as a spiritual guidance tool, for establishing a sacred space and as an aid to meditation and trance induction.",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. Lo sfondo è ispirato ai mandala: configurazioni geometriche di simboli. In varie tradizioni spirituali, i mandala possono essere utilizzati per concentrare l'attenzione dei praticanti e degli adepti, come strumento di guida spirituale, per creare uno spazio sacro e come supporto alla meditazione e all'induzione di trance."
      },
      "Digital Art", "1024px x 1024px", "2021",   
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "5",
      mask_mirrored, thumbnail_mask_mirrored,
      {"en": "Mirrored mask", "it": "Maschera specchiata"}, 
      {
        "en": "This digital artwork has been created with Procreate. It's a symmetrical mask, the geometry is similar to the one used for drawing mandalas symbols.",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. Si tratta di una maschera simmetrica, la cui geometria ricorda quella utilizzata per disegnare simboli mandala."
      },
      "Digital Art", "1024px x 1024px", "2020",   
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "6",
      snail_ufo, thumbnail_snail_ufo,
      {"en": "Aliens are real", "it": "Gli alieni esistono"}, 
      {
        "en": "This digital artwork has been created with Procreate. The concept behind it is that some animal species are so weird that they could almost be alien.",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. Il concetto che volevo esprimere è che alcune specie animali sono talmente strane da poter essere considerate quasi aliene."
      },
      "Digital Art", "1024px x 1024px", "2020",   
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "7",
      show_them, thumbnail_show_them,
      {"en": "Show them what you're made of", "it": "Fai vedere di cosa sei fatta"}, 
      {
        "en": "This digital artwork has been created with Procreate. The phrase it's an invitation to not hide out true selves.",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. La frase è un invito a non nascondere la nostra vera natura."
      },
      "Digital Art", "1024px x 1024px", "2020",   
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "8",
      ufo_desert, thumbnail_ufo_desert,
      {"en": "They're coming for you", "it": "Stanno arrivando per te"}, 
      {
        "en": "This digital artwork has been created with Procreate. I'm fascinated about the possibility of conscious beings coming from space. ",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. Sono affascinata dalla possibilità di esseri coscienti provenienti dallo spazio."
      },
      "Digital Art", "1024px x 1024px", "2020",   
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "9",
      wild_side, thumbnail_wild_side,
      {"en": "Take a walk on the wild side", "it": "Fatti un giro nel lato selvaggio"}, 
      {
        "en": "In this painting (and in some others, this is somewhat a recurring theme) I wanted to explore the idea of 'psychonauts', someone who explores altered states of consciousness, through hallucinogens, lucid dreaming, meditation or breathwork. Here the unexplored consciousness is represented by a desert that possesses eyes and the picture of the explorer was taken from an old magazine.",
        "it": "In questo dipinto (ed anche in altri, è per me un tema ricorrente) ho voluto esplorare l'idea degli 'psiconauti', persone che esplorano stati alterati di coscienza attraverso l'uso di allucinogeni, sogni lucidi, meditazione o tecniche di respirazione. Qui la coscienza inesplorata è rappresentata da un deserto che possiede degli occhi, mentre l'immagine dell'esploratore è stata presa da una vecchia rivista."
      },
      "Paintings", "34cm x 27cm", "2022",   
      {"en": "Collage and Acrylics on wood", "it": "Collage e Acrilici su legno"}, 
      {"en": "Psychonauts", "it": "Psychonauts"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "10",
      am_id_dreaming, thumbnail_am_id_dreaming,
      {"en": "Am I dreaming?", "it": "Sto sognando?"}, 
      {
        "en": "This painting was inspired by the 'reality-check' technique used to achieve lucid dreams. The technique involves asking the question 'Am I dreaming right now?' while consciously observing oneself, what one is doing, and the surrounding environment to determine if things don't add up. If absurdities are noticed, it might indicate being in a dream.",
        "it": "Questo dipinto è stato ispirato dalla tecnica di 'reality-check' che viene usata per riuscire ad avere sogni lucidi. La tecnica consiste nel porsi la domanda “In questo momento sto sognando?” nell'osservare con consapevolezza se stessi, quello che si sta facendo e l'ambiente circostante per capire se le cose non quadrano. Se si notano assurdità, forse, ci si trova in un sogno."
      },
      "Paintings", "15cm x 20cm", "2022",   
      {"en": "Acrylycs & beads on canva", "it": "Acrilici e perline su tela"}, 
      {"en": "Dreams", "it": "Dreams"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "11",
      azulejo, thumbnail_azulejo,
      {"en": "Azulejo", "it": "Azulejo"}, 
      {
        "en": "This painting was inspired by the typical portuguese decorated tiles called 'azulejos'. I visited portugual in august 2022 and was really fascinated by them. The eyes are made of plastic and come from an old halloween costume.",
        "it": "Questo dipinto è stato ispirato dalle tipiche piastrelle decorate portoghesi chiamate 'azulejos'. Ho visitato il Portogallo nell'agosto 2022 e ne sono rimasta davvero affascinata. Gli occhi sono fatti di plastica e provengono da un vecchio costume di Halloween."
      },
      "Paintings", "15cm x 15cm", "2022",   
      {"en": "Acrylycs & ornaments on canva", "it": "Acrilici e ornamenti su tela"}, 
      {"en": "Travel", "it": "Travel"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "12",
      flower_eyes_2, thumbnail_flower_eyes_2,
      {"en": "The eyes of the flowers - I", "it": "Gli occhi dei fiori - I"}, 
      {
        "en": "This painting was inspired by the idea of plant consciousness (the eyes do, in fact, represent being conscious). This is a theme that I'm fond of and I've created different versions of it.",
        "it": "Questo dipinto è stato ispirato dall'idea della coscienza delle piante (gli occhi, infatti, rappresentano l'essere coscienti). Questo è un tema a cui sono affezionata e ne ho creato diverse versioni."
      },
      "Paintings", "18cm x 18cm", "2021",   
      {"en": "Acrylics on canva", "it": "Acrilici su tela"}, 
      {"en": "Flowers with eyes", "it": "Flowers with eyes"}, 
      undefined, undefined
    ),
    new ArtGalleryItem(
      "13",
      flowers_eyes, thumbnail_flowers_eyes,
      {"en": "The eyes of the flowers - II", "it": "Gli occhi dei fiori - II"}, 
      {
        "en": "This painting was inspired by the idea of plant consciousness (the eyes do, in fact, represent being conscious). This is a theme that I'm fond of and I've created different versions of it.",
        "it": "Questo dipinto è stato ispirato dall'idea della coscienza delle piante (gli occhi, infatti, rappresentano l'essere coscienti). Questo è un tema a cui sono affezionata e ne ho creato diverse versioni."
      },
      "Paintings", "20cm x 24cm", "2022",   
      {"en": "Acrylics on canva", "it": "Acrilici su tela"}, 
      {"en": "Flowers with eyes", "it": "Flowers with eyes"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "14",
      mush_skull, thumbnail_mush_skull,
      {"en": "Skull eaten by mushrooms", "it": "Teschio divorato da funghi"}, 
      {
        "en": "This painting depicts a skull with its third eye open. The question that's being explored here is 'Will our consciousness outlive our physical bodies?' No one knows for sure, but it's a theme that greatly affected whole humanity.",
        "it": "Questo dipinto raffigura un teschio con il suo terzo occhio aperto. La domanda che si sta esplorando qui è 'La nostra coscienza sopravviverà ai nostri corpi fisici?' Nessuno lo sa con certezza, ma è un tema che ha profondamente influenzato l'intera umanità."
      },
      "Paintings", "34cm x 27cm", "2021",   
      {"en": "Acrylics on wood", "it": "Acrilici su legno"}, 
      {"en": "Skulls", "it": "Skulls"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "15",
      trilli_palace, thumbnail_trilli_palace,
      {"en": "My muse in an oniric palace", "it": "La mia musa in un palazzo onirico"}, 
      {
        "en": "My beloved kitty, that is, my muse, is depicted in a dreamlike palace with vibrant colors.",
        "it": "La mia amata gatta, cioè la mia musa, è rappresentata in un palazzo onirico con colori sgargianti."
      },
      "Paintings", "22cm x 22cm", "2021",   
      {"en": "Acrylics on canva", "it": "Acrilici su tela"}, 
      {"en": "Trilli", "it": "Trilli"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "16",
      eyes, thumbnail_eyes,
      {"en": "Altered eyes", "it": "Occhi alterati"}, 
      {
        "en": "The eyes that appear in this painting are altered: this is a reference to altered states of consciousness and to how these states change the visual perception of reality.",
        "it": "Gli occhi che appaiono in questo dipinto sono alterati: ciò è un riferimento agli stati alterati di coscienza e a come tali stati cambiano la percezione visiva della realtà."
      },
      "Paintings", "18cm x 18cm", "2021",
      {"en": "Acrylics on canva", "it": "Acrilici su tela"}, 
      {"en": "Altered states", "it": "Altered states"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "17",
      shared_thoughts, thumbnail_shared_thoughts,
      {"en": "Shared thoughts", "it": "Pensieri condivisi"}, 
      {
        "en": "A man and a woman (the pictures come from an old magazine) look eachother straight in the eyes and their intimacy allows their consciousness to touch and blend.",
        "it": "Un uomo e una donna (le immagini provengono da una vecchia rivista) si guardano negli occhi e la loro intimità permette alle loro coscienze di toccarsi e mescolarsi." 
      },
      "Paintings", "18cm x 18cm", "2021",
      {"en": "Acrylics on canva", "it": "Acrilici su tela"}, 
      {"en": "Altered states", "it": "Altered states"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "18",
      wanderer, thumbnail_wanderer,
      {"en": "Wanderer", "it": "Viandante"}, 
      {
        "en": "In this artwork (and in some others, this is somewhat a recurring theme) I wanted to explore the idea of 'psychonauts', someone who explores altered states of consciousness, through hallucinogens, lucid dreaming, meditation or breathwork. Here the unexplored consciousness is represented by a big and sparkly eye that appeared in the sky over a desert, and its explorer is a wanderer on a camel.", 
        "it": "In quest'opera d'arte (ed anche in altre, è un tema ricorrente), ho voluto esplorare l'idea degli 'psiconauti', persone che esplorano stati alterati di coscienza attraverso allucinogeni, sogni lucidi, meditazione o tecniche di respirazione. Qui la coscienza inesplorata è rappresentata da un grande e scintillante occhio che è apparso nel cielo sopra un deserto, e il suo esploratore è un viandante su un cammello." 
      },
      "Paintings", "18cm x 18cm", "2022",
      {"en": "Acrylics & collage on canva", "it": "Acrilici e collage su tela"}, 
      {"en": "Psychonauts", "it": "Psychonauts"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "19",
      consciosness_memory_anticipation, thumbnail_consciousness_memory_anticipation,
      {"en": "Consciousness = memory + anticipation", "it": "Coscienza = memoria + anticipazione"}, 
      {
        "en": "Free association of images (both painted and pictures from an old magazine) to represent the idea that consciousness is made up by memory and anticipation.",
        "it": "Associazione libera di immagini (sia dipinte che da una vecchia rivista) per rappresentare l'idea che la coscienza sia formata dalla memoria e dall'anticipazione." 
      },
      "Paintings", "30cm x 24cm", "2022",
      {"en": "Collage & acrylics on canva", "it": "Collage e acrilici su tela"}, 
      {"en": "Consciousness", "it": "Consciousness"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "20",
      wolkswagen, thumbnail_wolkswagen,
      {"en": "Magic bus", "it": "Bus magico"}, 
      {
        "en": "This digital artwork has been created with Procreate. The Volkswagen bus is one of the most famous symbols of hippies and the counterculture of the 1960s. ",
        "it": "Quest'opera d'arte digitale è stata creata con Procreate. Il bus Volkswagen è uno dei simboli più famosi degli hippie e della controcultura degli anni '60."
  
      },
      "Digital Art", "1024px x 1024px", "2021",
      {"en": "Procreate", "it": "Procreate"}, 
      {"en": "Procreate", "it": "Procreate"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "21",
      vase1, thumbnail_vase1, 
      {"en": "Smiley pen holder", "it": "Portapenne smiley"}, 
      {
        "en": "I made this pen holder using the coiling technique. The ceramic was painted with slip and crystalline glaze.", 
        "it": "Ho realizzato questo portapenne con la tecnica 'del colombino'. La ceramica è stata dipinta con ingobbio e cristallina."}, 
      "Ceramics", "6cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "22",
      vase2, thumbnail_vase2, 
      {"en": "Melting pen holder", "it": "Portapenne sciolto"}, 
      {
        "en": "I made this pen holder using the pinching technique. The ceramic was painted with red (on the inside) and white glaze.", 
        "it": "Ho realizzato questo portapenne con la tecnica 'del pizzicato'. La ceramica è stata dipinta con smalto rosso (all'interno) e bianco." 
      },
      "Ceramics", "8cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "23",
      mask, thumbnail_mask, 
      {"en": "Maschera in ceramica", "it": "Ceramic mask"}, 
      {
        "en": 
        <div>I got the inspiration for this ceramic mask from the designs generated by AI 
          (that you can look at <Link to="/art/collections/2"> here</Link>).
        </div>, 
        "it": <div>Ho tratto ispirazione per questa maschera in ceramica dai design generati dall'IA (che si possono vedere <Link to="/art/collections/2"> qui</Link>).</div>
      },
      "Ceramics", "25cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "24",
      vase3, thumbnail_vase3, 
      {"en": "Single flower vase", "it": "Vaso per fiore singolo"}, 
      { 
        "en": "I made this vase using the plate technique. The ceramic was painted with blue, yellow and red glaze.", 
        "it": "Ho realizzato questo vaso con la tecnica 'della lastra'. La ceramica è stata dipinta con smalto rosso, giallo e blu."
        }, 
      "Ceramics", "13cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "99",
      vase4, thumbnail_vase4, 
      {"en": "Wheel experiment - IV", "it": "Esperimento al tornio - IV"}, 
      {
        "en": "This vase was my fourth work on the pottery wheel. It's a vase for flowers (or a pen holder), painted with blue and white glaze.", 
        "it": "Questo vaso è il mio quarto lavoro al tornio. Si tratta di un vaso portafiori (o di un portapenne), dipinto con smalto blu e bianco."}, 
      "Ceramics", "11cm", "2023",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "26",
      vase5, thumbnail_vase5, 
      {"en": "Wavy tray", "it": "Svuotatasche a onde"}, 
      {
        "en": "I made this tray using the pinching technique. The ceramic was painted with blue and white glaze. ", 
        "it": "Ho realizzato questo svuotatasche con la tecnica 'del pizzicato'. La ceramica è stata poi dipinta con smalto blu e bianco."}, 
      "Ceramics", "7cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "27",
      vase6, thumbnail_vase6, 
      {"en": "Tentacule tray", "it": "Svuotatasche con tentacolo"}, 
      {
        "en": "I made this tray using the pinching technique. The ceramic was painted with blue, light blue and white glaze. ", 
        "it": "Ho realizzato questo svuotatasche con la tecnica 'del pizzicato'. La ceramica è stata poi dipinta con smalto blu, azzurro e bianco."}, 
      "Ceramics", "7cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),   
    new ArtGalleryItem(
      "28",
      vase7, thumbnail_vase7, 
      {"en": "Coffee cup", "it": "Tazzina da caffè"}, 
      {
        "en": "I made this coffee cup using the 'pinch technique' and painted it with colored slips and crystalline glaze.", 
        "it": "Ho realizzato questa tazzina da caffè con la tecnica 'del pizzicato' e l'ho dipinta con ingobbi colorati e cristallina."}, 
      "Ceramics", "3.5cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "31",
      vase10, thumbnail_vase10, 
      {"en": "Wheel experiment - III", "it": "Esperimento al tornio - III"},
      {
        "en": "This vase was my third work on the pottery wheel. It's a vase for flowers (or a pen holder), painted with red and white glaze.", 
        "it": "Questo vaso è il mio terzo lavoro al tornio. Si tratta di un vaso portafiori (o di un portapenne), dipinto con smalto rosso e bianco."}, 
      "Ceramics", "8cm", "2023",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "30",
      vase8, thumbnail_vase8, 
      {"en": "Wheel experiment - II", "it": "Esperimento al tornio - II"},
      {
        "en": "This bowl was my second work on the pottery wheel. It's been painted with pink and white glaze.", 
        "it": "Questa ciotola è stata il mio secondo lavoro al tornio. È stata dipinta con smalto bianco e rosa."}, 
      "Ceramics", "6cm", "2023",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "29",
      vase9, thumbnail_vase9, 
      {"en": "Wheel experiment - I", "it": "Esperimento al tornio - I"}, 
      {
        "en": "This bowl was my first work on the pottery wheel. It's been painted with violet and yellow glaze.", 
        "it": "Questa ciotola è stata il mio primo lavoro al tornio. È stata dipinta con smalto viola e giallo."}, 
      "Ceramics", "7cm", "2023",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),  
    
    new ArtGalleryItem(
      "32",
      vase11, thumbnail_vase11, 
      {"en": "Square tray", "it": "Svuotatasche quadrato"}, 
      {
        "en": "This square tray was made using the 'slab technique.' I then used a petroleum green glaze to paint it.", 
        "it": "Questo svuotatasche quadrato è stato realizzato con la tecnica 'della lastra'. Ho poi utilizzato uno smalto verde petrolio per dipingerlo."}, 
      "Ceramics", "5cm", "2022",  
      {"en": "Ceramic", "it": "Ceramica"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "33",
      solipsism, thumbnail_solipsism, 
      {"en": "Solipsism", "it": "Solipsismo"}, 
      {
        "en": "In this sketch I tried to represent the concept of 'solipsism'. Solipsism consists in the philosophical belief that only one's own mind and existence are certain to exist or be known, and nothing else: the whole outside world might be a sort of movie projected by consciousness.", 
        "it": "In questo disegno ho cercato di rappresentare il concetto di 'solipsismo'. Il solipsismo consiste nella convinzione filosofica che solo la propria mente e esistenza siano certe di esistere o essere conosciute, e nient'altro: l'intero mondo esterno potrebbe essere una sorta di film proiettato dalla coscienza."}, 
      "Paintings", "20cm x 15cm", "2021",  
      {"en": "Acrylics on paper", "it": "Acrilici su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "34",
      bauhaus_1, thumbnail_bauhaus_1, 
      {"en": "Bauhaus audio visualizer", "it": "Visualizzatore audio Bauhaus"}, 
      {
        "en": 
          <div>
            This artwork is made with pure code. It has been coded with javascript (specifically, using the canvas-sketch js library). 
            This is just a screen-recording turned into a gif, but the real artwork consists in an audio visualizer (the eye's lashes are reactive to music). 
            The inspiration for this animation comes from 
            <a href="https://cdn.shopify.com/s/files/1/0416/9056/2714/products/Bauhausposterdesign.jpg?v=1655219395" target="_blank" rel="noopener noreferrer"> this Bauhaus poster</a>.
          </div>, 
        "it": 
          <div>
            Questa opera d'arte è realizzata con puro codice. È stata programmata in JavaScript (in particolare, utilizzando la libreria canvas-sketch js). 
            Questa è solo una registrazione dello schermo trasformata in gif, ma la vera opera d'arte consiste in un visualizzatore di audio (le ciglia degli occhi sono reattive alla musica). 
            L'ispirazione per questa animazione proviene da 
            <a href="https://cdn.shopify.com/s/files/1/0416/9056/2714/products/Bauhausposterdesign.jpg?v=1655219395" target="_blank" rel="noopener noreferrer"> questo poster Bauhaus</a>.
          </div>
        }, 
      "Digital Art", "810px x 1080px", "2023",  
      {"en": "Javascript", "it": "Javascript"}, 
      {"en": "Creative Coding", "it": "Creative Coding"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "35",
      bauhaus_2, thumbnail_bauhaus_2, 
      {"en": "Bauhaus mouse tracker", "it": "Mouse tracker Bauhaus"}, 
      {
        "en": <div>
        This artwork is made with pure code. It has been coded with javascript (specifically, using the canvas-sketch js library). 
        This is just a screen-recording turned into a gif, but the real artwork can track the touch of the mouse (the lines are moved by it). 
        The inspiration for this animation comes from 
        <a href="https://gallerix.it/gallery/5/2/6/1/35060-800.jpg" target="_blank" rel="noopener noreferrer"> this Bauhaus poster</a>.
      </div>, 
        "it":
        <div>
          Questa opera d'arte è realizzata con puro codice. È stata programmata in JavaScript (in particolare, utilizzando la libreria canvas-sketch js). 
          Questa è solo una registrazione dello schermo trasformata in gif, ma la vera opera d'arte traccia il tocco del mouse (le linee sono mosse proprio da esso). 
          L'ispirazione per questa animazione proviene da 
          <a href="https://gallerix.it/gallery/5/2/6/1/35060-800.jpg" target="_blank" rel="noopener noreferrer"> questo poster Bauhaus</a>.
        </div>}, 
      "Digital Art", "810px x 1080px", "2023", 
      {"en": "Javascript", "it": "Javascript"}, 
      {"en": "Creative Coding", "it": "Creative Coding"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "36",
      coffee, thumbnail_coffee, 
      {"en": "Coffee cup", "it": "Tazza di caffè"}, 
      {
        "en": "This is just a simple study I made while training my oil painting skills. But I also love coffee (and by the way, did you know that caffeine is a psychoactive, i.e. mind altering, substance?).", 
        "it": "Questo è un seplice studio che ho realizzato per allenarmi con la pittura ad olio. Però amo anche il caffè (e a proposito, sapevi che la caffeina è una sostanza psicoattiva, cioè che altera la mente?)."}, 
      "Paintings", "20cm x 15cm", "2023",  
      {"en": "Oil painting on paper", "it": "Colori ad olio su carta"}, 
      {"en": "Oil", "it": "Oil"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "37",
      mushrooms, thumbnail_mushrooms, 
      {"en": "Mushrooms", "it": "Funghi"}, 
      {
        "en": "Mushrooms are amazing and mysterious creatures (here the eyes, again, represent the possibility that they possess some form of consciousness we cannot understand).", 
        "it": "I funghi sono creature sorprendenti e misteriose (qui gli occhi, ancora una volta, rappresentano la possibilità che essi possiedano una forma di coscienza che non possiamo comprendere)."}, 
      "Paintings", "20cm x 15cm", "2021",  
      {"en": "Acrylics on paper", "it": "Acrilici su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "38",
      natura_morta, thumbnail_natura_morta, 
      {"en": "Still life", "it": "Natura morta"}, 
      {
        "en": "Super simple still life sketch.", 
        "it": "Disegno molto semplice di una natura morta."}, 
      "Paintings", "20cm x 15cm", "2023",  
      {"en": "Sketch on paper", "it": "Disegno su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "39",
      astronaut, thumbnail_astronaut, 
      {"en": "Astronaut of inner space", "it": "Astronauta dello spazio interno"}, 
      {
        "en": "In this artwork (and in some others, this is somewhat a recurring theme) I wanted to explore the idea of 'psychonauts', someone who explores altered states of consciousness, through hallucinogens, lucid dreaming, meditation or breathwork. Here both the unexplored consciousness and its explorer are pictures taken from old magazines, wich I used to create a collage.", 
        "it": "In questa opera (ed anche in altre, è un tema ricorrente) ho voluto esplorare l'idea degli 'psiconauti', persone che esplorano stati alterati di coscienza attraverso allucinogeni, sogni lucidi, meditazione o tecniche di respirazione. Qui sia la coscienza inesplorata che il suo esploratore sono immagini prese da vecchie riviste, che ho usato per creare un collage."}, 
      "Paintings", "30cm x 20cm", "2021",  
      {"en": "Collage on cardboard", "it": "Collage su cartoncino"}, 
      {"en": "Psychonauts", "it": "Psychonauts"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "40",
      monnalisa, thumbnail_monnalisa, 
      {"en": "Ginevra de' Benci's mind", "it": "La mente di Ginevra de' Benci"}, 
      {
        "en": "This collage is made using Leonardo Da Vinci's portrait of Ginevra de' Benci. I 'opened her mind' to let her thoughts wander and fly through the air.", 
        "it": "Questo collage è realizzato utilizzando il ritratto di Ginevra de' Benci di Leonardo Da Vinci. Ho 'aperto la sua mente' per permettere ai suoi pensieri di vagare e volare nell'aria."}, 
      "Paintings", "20cm x 15cm", "2020",  
      {"en": "Collage & pencils on paper", "it": "Collage e pastelli su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "41",
      lebron, thumbnail_lebron, 
      {"en": "Lebron James", "it": "Lebron James"}, 
      {
        "en": "This drawing was made as a present for a huge fan of Lebron James.", 
        "it": "Questo disegno è stato fatto come regalo per un grande fan di LeBron James."}, 
      "Paintings", "20cm x 15cm", "2019",  
      {"en": "Sketch on paper", "it": "Disegno su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ),  
    new ArtGalleryItem(
      "42",
      read_real, thumbnail_read_real, 
      {"en": "Books", "it": "Libri"}, 
      {
        "en": "I've always love reading and this is just a depiction of my stack of books waiting for me. ", 
        "it": "Ho sempre amato leggere e questa è semplicemente una rappresentazione della mia pila di libri che mi attende. "}, 
      "Paintings", "20cm x 15cm", "2023",  
      {"en": "Sketch on paper", "it": "Disegno su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "43",
      table, thumbnail_table, 
      {"en": "Coffee table", "it": "Tavolino da salotto"}, 
      {
        "en": "I sketched my coffee table with pencils.", 
        "it": "Ho disegnato il mio tavolino da salotto con pastelli."}, 
      "Paintings", "20cm x 15cm", "2023",  
      {"en": "Sketch on paper", "it": "Disegno su carta"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "44",
      lets_get_high, thumbnail_lets_get_high, 
      {"en": "Let's get high!", "it": "Andiamo in alto!"}, 
      {
        "en": "The title says it all.", 
        "it": "Il titolo spiega tutto."}, 
      "Paintings", "20cm x 15cm", "2020",  
      {"en": "Collage & acrylics on paper", "it": "Collage e acrilici su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "45",
      plants, thumbnail_plants, 
      {"en": "Plants in vases", "it": "Piante in vaso"}, 
      {
        "en": "Just some plants chilling in their vases.", 
        "it": "Semplicemente delle piante che si rilassano nei propri vasi."}, 
      "Paintings", "20cm x 15cm", "2021",  
      {"en": "Acrylics on paper", "it": "Acrilici su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "46",
      melt_face, thumbnail_melt_face, 
      {"en": "Melting face", "it": "Faccia che si scioglie"}, 
      {
        "en": "This person's face is melting: it's a metaphor for the alteration of the perception of the self that happens as a consequence of some neurogical condiditions, for example the loss of proprioception.", 
        "it": "Il volto di questa persona si sta sciogliendo: è una metafora per l'alterazione della percezione di sé che avviene a conseguenza di alcune condizioni neurologiche, ad esempio la perdita della propriocezione."}, 
      "Paintings", "20cm x 15cm", "2023",  
      {"en": "Acrylics on paper", "it": "Acrilici su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "47",
      sun_mountains, thumbnail_sun_mountains, 
      {"en": "Sun through the mountains", "it": "Sole attraverso le montagne"}, 
      {
        "en": "The sun (represented by a picture of a mask taken from an old magazine) is rising through the mountains.", 
        "it": "Il sole (rappresentato da un'immagine di una maschera presa da una vecchia rivista) sta sorgendo tra le montagne."}, 
      "Paintings", "20cm x 15cm", "2020",  
      {"en": "Collage & acrylics on paper", "it": "Collage e acrilici su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "48",
      stay_with_me_a_while, thumbnail_stay_with_me_a_while, 
      {"en": "Stay with me a while", "it": "Stai con me per un po'"}, 
      {
        "en": 
        <div>
          I created this drawing through free association, letting myself be guided by the song 
          <a href="https://www.youtube.com/watch?v=9bWGy0K5VF0" target="_blank" rel="noopener noreferrer"> 'Sara' by Fleetwood Mac</a>. 
          I used an old magazine for the collage images.
        </div>, 
        "it": 
        <div>
          Ho creato questo disegno tramite libera associazione, lasciandomi guidare dalla canzone 
          <a href="https://www.youtube.com/watch?v=9bWGy0K5VF0" target="_blank" rel="noopener noreferrer"> 'Sara' dei Fleetwood Mac</a>. 
          Ho utilizzato una vecchia rivista per le immagini da collage.
        </div>}, 
      "Paintings", "20cm x 15cm", "2020",  
      {"en": "Collage & sketch on paper", "it": "Collage e disegno su carta"}, 
      {"en": "Sketches", "it": "Sketches"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "999",
      turn_on_the_lights, turn_on_the_lights, 
      {"en": "Turn on the lights", "it": "Accendi la luce"}, 
      {
        "en": "The lights (upcycled christmas lights) represent the electrical signals that pass between neurons and generate brain activity.",
        "it": "Le luci (luci di natale riutilizzate) rappresentano i segnali elettrici che passano tra neuroni generando l'attività cerebrale."}, 
      "Paintings", "35cm x 27cm", "2023",  
      {"en": "Acrylics and lights on canva", "it": "Acrilici e luci su tela"}, 
      {"en": "Lights", "it": "Lights"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "998",
      infinite_zoom_flower_eyes_1, infinite_zoom_flower_eyes_1, 
      {"en": "Infinite Zoom on my painting", "it": "Zoom infinito sul mio dipinto"}, 
      {
        "en": <div>
          The video has been created using stable diffusion generative AI model. I started from one of my own painting 
          <Link to="/art/13">(this one)</Link> and tried to create a context for it. 
        </div>,
        "it": <div>
        Il video è stato creato utilizzando il modello generativo di intelligenza artificiale stable diffusion. Sono partita da uno dei miei dipinti 
        <Link to="/art/13">(questo)</Link> e ho provato a creargli un contesto intorno.
      </div>
      },
      "Digital Art", "1440px x 1778px", "2024",  
      {"en": "AI Generated", "it": "Generato da IA"}, 
      {"en": "infinite-zoom", "it": "infinite-zoom"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "997",
      touchdesigner1, touchdesigner1, 
      {"en": "Touchdesigner I", "it": "Touchdesigner I"}, 
      {
        "en": "My first experiment with touchdesigner. The quote comes from the book 'The Doors of Perception' by Aldous Huxley.",
        "it": "Il mio primo esperimento con touchdesigner. La citazione viene dal libro 'Le Porte della Percezione' di Aldous Huxley."}, 
      "Digital Art", undefined, "2023",  
      {"en": "Touchdesigner", "it": "Touchdesigner"}, 
      {"en": "Touchdesigner", "it": "Touchdesigner"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "9999",
      flowers_eyes_3, flowers_eyes_3, 
      {"en": "The eyes of the flowers - III", "it": "Gli occhi dei fiori - III"}, 
      {
        "en": "This painting was inspired by the idea of plant consciousness (the eyes do, in fact, represent being conscious). This is a theme that I'm fond of and I've created different versions of it.",
        "it": "Questo dipinto è stato ispirato dall'idea della coscienza delle piante (gli occhi, infatti, rappresentano l'essere coscienti). Questo è un tema a cui sono affezionata e ne ho creato diverse versioni."
      },
      "Paintings", "30cm x 40cm", "2023",   
      {"en": "Acrylics on canva", "it": "Acrilici su tela"}, 
      {"en": "Flowers with eyes", "it": "Flowers with eyes"}, 
      undefined, undefined
      ), 
    new ArtGalleryItem(
      "995",
      pisque_clay, pisque_clay, 
      {"en": "Tris of small bowls", "it": "Tris di piccole ciotole"}, 
      {
        "en": "I made these small bowls on the pottery wheel.", 
        "it": "Ho realizzate piccole ciotole al tornio."}, 
      "Ceramics", "600px x 600px", "2023",  
      {"en": "Clay", "it": "Argilla"}, 
      {"en": "Ceramic", "it": "Ceramica"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "994",
      floating_city, floating_city, 
      {"en": "Floating city", "it": "Città galleggiante"}, 
      {
        "en": "I made this digital collage using a Wacom tablet and Gimp. ", 
        "it": "Ho realizzate questo collage digitale utilizzando una tavoletta Wacom e Gimp. "}, 
      "Digital Art", "600px x 600px", "2019",  
      {"en": "Gimp", "it": "Gimp"}, 
      {"en": "Digital Collage", "it": "Collage Digitale"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "993",
      woman_with_mantilla, woman_with_mantilla, 
      {"en": "Woman with mantilla", "it": "Donna con mantella"}, 
      {
        "en": "I made this digital artwork using a Wacom tablet and Gimp.", 
        "it": "Ho realizzato questa opera d'arte digitale utilizzando una tavoletta Wacom e Gimp. "}, 
      "Digital Art", "600px x 600px", "2018",  
      {"en": "Gimp", "it": "Gimp"}, 
      {"en": "Digital Art", "it": "Digital Art"}, 
      undefined, undefined
      ),
    new ArtGalleryItem(
      "9998",
      infinite_zoom_2, infinite_zoom_2, 
      {"en": "Infinite Zoom on my painting - II", "it": "Zoom infinito sul mio dipinto - II"}, 
      {
        "en": <div>
          The video has been created using stable diffusion generative AI model. I started from one of my own painting 
          <Link to="/art/9999">(this one)</Link> and tried to create a context for it. 
        </div>,
        "it": <div>
        Il video è stato creato utilizzando il modello generativo di intelligenza artificiale stable diffusion. Sono partita da uno dei miei dipinti 
        <Link to="/art/9999">(questo)</Link> e ho provato a creargli un contesto intorno.
      </div>
      },
      "Digital Art", "1440px x 1958px", "2024",  
      {"en": "AI Generated", "it": "Generato da IA"}, 
      {"en": "infinite-zoom", "it": "infinite-zoom"}, 
      undefined, undefined
      ), 
];