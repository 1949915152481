import { TechWorkExperience } from "./models/TechWorkExperience.ts";

const TechWorkExperiences = [
    new TechWorkExperience(
        "09.2023", "ongoing",
        "Freelance Machine Learning & Software Developer",
        "",
        null,
        {"en": "Milan, Italy", "it": "Milano"},
        {"en": 
        <span>
            I develop tailored solutions for my clients by leveraging my expertise in the areas of software development, machine learning and data analysis. From defining requirements to deployment, I build robust and versatile solutions that meet diverse project needs.
            <li>Generative AI and large language models (LLMs) </li> 
            <li>High proficiency in cloud services (Azure, AWS, Google Cloud), Python, JavaScript, C#, data analysis, AI-based applications development</li>
        </span>, 
        "it": 
        <span>
            Sviluppo di soluzioni personalizzate per i miei clienti sfruttando la mia esperienza nelle aree dello sviluppo software, del machine learning e dell'analisi dei dati. Dalla definizione dei requisiti alla distribuzione, costruisco soluzioni robuste e versatili che soddisfano le diverse esigenze progettuali.
            <li>Intelligenza artificiale generativa e Large Language Models (LLM)</li>
            <li>Alta competenza nei servizi cloud (Azure, AWS, Google Cloud), Python, JavaScript, C#, analisi dei dati, sviluppo di applicazioni basate su intelligenza artificiale</li>
        </span>
        }
    ),
    new TechWorkExperience(
        "09.2022", "07.2023",
        "Machine Learning & Software Engineer",
        "SmartCow",
        "https://www.smartcow.ai/",
        {"en": "Milan, Italy", "it": "Milano"},
        {"en": 
        (<span>
            <li>Nvidia Omniverse Extensions Development</li>
            <li>Python programming</li>
            <li>Python data-intensive web applications development (with fastAPI)</li>
            <li>MongoDB database design</li>
            <li>MLFlow model registry integration development</li>
        </span>), 
        "it": 
        (<span>
            <li>Sviluppo di estensioni Nvidia Omniverse</li>
            <li>Programmazione Python</li>
            <li>Sviluppo di applicazioni web intensivamente basate su dati in Python (con fastAPI)</li>
            <li>Progettazione di database MongoDB</li>
            <li>Sviluppo di integrazione del registro dei modelli MLFlow</li>
        </span>)}
    ),
    new TechWorkExperience(
        "09.2021", "09.2022",
        "Machine Learning & Software Developer",
        "e-Novia",
        "https://e-novia.it/en",
        {"en": "Milan, Italy", "it": "Milano"},
        {"en": 
        (<span>
        I've developed and designed software platforms and applications, handling IoT data streams and databases. While developing these applications I became familiar with the Microsoft Azure Cloud tools (functions, SignalR, EventHub, databases). My main tasks included: 
            <li>C#, Python programming</li>
            <li>Web applications development</li>
            <li>Development of REST APIs, websockets, webhooks</li>
            <li>Usage of Azure Cloud services, including functions, SQL database, BlobStorage and TableStorage, SignalR, EventHub</li>
        I feel particularly proud of working on developing a webapp for <a href="https://tokbo.it/en/" target="_blank" rel="noreferrer">Tokbo</a>, "The intelligent talking bolt", an IoT based application that allows to monitor remotely the status of bolts in infrastructures. 
        </span>),
         "it": (
            <span>
            Ho sviluppato e progettato piattaforme e applicazioni software, gestendo flussi di dati IoT e database. Durante lo sviluppo di queste applicazioni mi sono familiarizzato con gli strumenti di Microsoft Azure Cloud (funzioni, SignalR, EventHub, database). I miei principali compiti includevano: 
                <li>Programmazione in C# e Python</li>
                <li>Sviluppo di applicazioni web</li>
                <li>Sviluppo di REST API, websockets, webhooks</li>
                <li>Utilizzo dei servizi di Azure Cloud, inclusi funzioni, database SQL, BlobStorage e TableStorage, SignalR, EventHub</li>
            Sono particolarmente orgogliosa di aver lavorato allo sviluppo di una webapp per <a href="https://tokbo.it/en/" target="_blank" rel="noreferrer">Tokbo</a>, "La vite intelligente parlante", un'applicazione basata su tecnologia IoT che consente di monitorare a distanza lo stato delle viti nelle infrastrutture.
            </span>
         )},
    ),
    new TechWorkExperience(
        "09.2019", "09.2021",
        "Data & Machine Learning Scientist",
        "Machine Learning Reply",
        "https://www.reply.com/machine-learning-reply/it/",
        {"en": "Milan, Italy", "it": "Milano"},
        {"en": "I’ve been working on developing various machine learning applications and solutions, including: recommender systems for e-commerce websites, text classification models, semantic search, computer vision solutions (image segmentation, image retrieval, visual search). While developing these applications I became familiar with the tools offered of the Google Cloud and AWS cloud ecosystems, for example: BigQuery, Dataproc, DataFlow, PubSub, AI Platform, AI APIs (GCP); Lambda, Sagemaker, Redshift, S3 (AWS). Other tasks included: Data Analysis and Visualization, Python programming.",
         "it": "Ho lavorato allo sviluppo di varie applicazioni e soluzioni di apprendimento automatico, tra cui: sistemi di raccomandazione per siti web di e-commerce, modelli di classificazione del testo, ricerca semantica, soluzioni di computer vision (segmentazione delle immagini, recupero delle immagini, ricerca visiva). Durante lo sviluppo di queste applicazioni mi sono familiarizzato con gli strumenti offerti dagli ecosistemi cloud di Google Cloud e AWS, ad esempio: BigQuery, Dataproc, DataFlow, PubSub, AI Platform, AI APIs (GCP); Lambda, Sagemaker, Redshift, S3 (AWS). Altre attività includono: analisi e visualizzazione dei dati, programmazione Python."}
    ),
    new TechWorkExperience(
        "03.2019", "07.2019",
        "Developer (stage)",
        "Ticketag",
        "https://www.ticketag.dev/",
        {"en": "Milan, Italy", "it": "Milano"},
        {"en": "The purpose of the stage was researching on an artificial vision system that could automatically extract structured informations from images of football clubs membership cards and identity documents. This project was the subject of my master degree thesis.",
         "it": "Lo scopo dello stage era fare ricerca su un sistema di visione artificiale in grado di estrarre automaticamente informazioni strutturate dalle immagini che rappresentavano tessere di iscrizione a club di calcio e documenti di identità. Questo progetto è stato oggetto della mia tesi di laurea magistrale."}
    ),
    new TechWorkExperience(
        "05.2018", "07.2019",
        "Python Pogramming Tutor",
        "Università degli studi di Milano - Biococca",
        "https://www.unimib.it/",
        {"en": "Milan, Italy", "it": "Milano"},
        {"en": "The tutoring activity was provided for students of the 'Scienze Statistiche ed Economiche' (statistics) degree course at University of Milano - Bicocca. The activity included: lectures and lab lessons (about conditionals, loops, complex data structures, functions, file reading and writing, object oriented programming) and the production of useful educational material about Python programming language.",
         "it": "L'attività di tutoraggio è stata fornita agli studenti del corso di laurea in 'Scienze Statistiche ed Economiche' presso l'Università di Milano - Bicocca. L'attività includeva: lezioni e lezioni di laboratorio (sui condizionali, cicli, strutture dati complesse, funzioni, lettura e scrittura di file, programmazione orientata agli oggetti) e la produzione di materiale didattico utile sul linguaggio di programmazione Python."}
    ),
    new TechWorkExperience(
        "02.2017", "05.2017",
        "Internship Student",
        "Università degli studi di Milano - Biococca",
        "https://www.unimib.it/",
        {"en": "Milan, Italy", "it": "Milano"},
        {"en": (
            <span>
                The main purpose of the internship was the development of my thesis project: automatically extract skills from job offers (using natural language processing, neural networks, graph databases). My thesis project was part of a bigger project financed by <a href="https://www.cedefop.europa.eu/en" target="_blank" rel="noreferrer">CEDEFOP (European Centre for the Development of Vocational Training)</a>, currently object of research at <a href="https://crispresearch.it/" target="_blank" rel="noreferrer">CRISP</a>. During the internship I became familiar with the following concepts:
                <li>Information Retrieval from raw text</li>
                <li>Text mining: keyword extraction, word embedding</li>
                <li>Unsupervised semantic classification of words</li>
                <li>GraphDB</li>
                <li>Graph analysis, clique identification</li>
            </span>
        ),
         "it": (
            <span>
                Il principale scopo dello stage era lo sviluppo del mio progetto di tesi: l'estrazione automatica di skill dalle offerte di lavoro (utilizzando l'elaborazione del linguaggio naturale, le reti neurali e i database a grafo). Il mio progetto di era parte di un progetto più ampio finanziato da <a href="https://www.cedefop.europa.eu/en" target="_blank" rel="noreferrer">CEDEFOP (Centro europeo per lo sviluppo della formazione professionale)</a>, attualmente oggetto di ricerca presso <a href="https://crispresearch.it/" target="_blank" rel="noreferrer">CRISP</a>. Durante lo stage ho preso familiarità con i seguenti concetti:
                <li>Information retrieval da testo in linguaggio naturale</li>
                <li>Text mining: estrazione di keywords, word embeddings</li>
                <li>Classificazione semantica non supervisionata delle parole</li>
                <li>Database a grafo</li>
                <li>Analisi dei grafi, identificazione di clique</li>
            </span>
         )}
    )
];

export default TechWorkExperiences;