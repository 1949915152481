import React, { useState, useEffect } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppContextProvider } from "./components/context/AppContext";
import ResponsiveApp from "./components/ResponsiveApp";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [isGlitchActive, setIsGlitchActive] = useState(false);

  const handleGlitchHover = () => {
    setIsGlitchActive(true);
  };

  useEffect(() => {
    if (isGlitchActive) {
      const timer = setTimeout(() => {
        setIsGlitchActive(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isGlitchActive]);

  return (
    <AppContextProvider>
      <Router>
        <ScrollToTop>
          < ResponsiveApp isGlitchActive={isGlitchActive} handleGlitchHover={handleGlitchHover} />
        </ScrollToTop>
      </Router>
    </AppContextProvider>
  );
}

export default App;
