import { NewsItem } from "./models/NewsItem.ts";

export const NewsItems = [
    new NewsItem(
        "07/02/2024",
        {"en": "New article 'Infinite-Zooming on my Paintings with Stable Diffusion'", "it": "Nuovo articolo 'Infinite-Zooming on my Paintings with Stable Diffusion'"},
        {
            "en": 
            <div>
                I published an article on Medium, its title is 'Infinite-Zooming on my Paintings with Stable Diffusion'. 
                It's about an artistic experiment I made with the use of AI (stable diffusion) to generate infinite-zoom like animations on my paintings. 
                Read it <a target="_blank" rel="noopener noreferrer" href="https://medium.com/intelligent-art/infinite-zooming-on-my-paintings-with-stable-diffusion-a640419b5c9b">here</a>
            </div>, 
            "it": 
            <div>
                Ho pubblicato un articolo su Medium, il cui titolo è 'Infinite-Zooming on my Paintings with Stable Diffusion'. 
                Tratta di come l'intelligenza artificiale stia rivoluzionando l'interazione uomo-computer. Man mano che continua a progredire, trasformerà 
                ulteriormente il modo in cui comunichiamo e utilizziamo la tecnologia, plasmando la nostra vita quotidiana e guidando l'innovazione in vari settori. 
                Secondo me, il vero potenziale dell'IA risiede nell'intelligenza collaborativa, promettendo un futuro in cui gli esseri umani e l'IA lavorano a stretto 
                contatto per ridefinire l'innovazione e plasmare il nostro mondo. Leggilo <a target="_blank" rel="noopener noreferrer" href="https://medium.com/intelligent-art/infinite-zooming-on-my-paintings-with-stable-diffusion-a640419b5c9b">qui</a>.
            </div>
        }
    ),
    new NewsItem(
        "20/09/2023",
        {"en": "New article 'The AI Revolution in Human-Computer Interaction: Embracing Collaborative Intelligence'", "it": "Nuovo articolo 'The AI Revolution in Human-Computer Interaction: Embracing Collaborative Intelligence'"},
        {
            "en": 
            <div>
                I published an article on Medium, its title is 'The AI Revolution in Human-Computer Interaction: Embracing Collaborative Intelligence'. 
                It's about how artificial intelligence is revolutionizing human-computer interaction. As it continues to advance, it will further transform the way we 
                communicate with and use technology, shaping our daily lives and driving innovation across various sectors. In my opinion, the true potential of AI 
                lies in collaborative intelligence, promising a future where humans and AI work hand in hand 
                to redefine innovation and reshape our world. Read it <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@sarasisti.mi/the-ai-revolution-in-human-computer-interaction-embracing-collaborative-intelligence-e063dedeb49f">here</a>
            </div>, 
            "it": 
            <div>
                Ho pubblicato un articolo su Medium, il cui titolo è 'The AI Revolution in Human-Computer Interaction: Embracing Collaborative Intelligence'. 
                Tratta di come l'intelligenza artificiale stia rivoluzionando l'interazione uomo-computer. Man mano che continua a progredire, trasformerà 
                ulteriormente il modo in cui comunichiamo e utilizziamo la tecnologia, plasmando la nostra vita quotidiana e guidando l'innovazione in vari settori. 
                Secondo me, il vero potenziale dell'IA risiede nell'intelligenza collaborativa, promettendo un futuro in cui gli esseri umani e l'IA lavorano a stretto 
                contatto per ridefinire l'innovazione e plasmare il nostro mondo. Leggilo <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@sarasisti.mi/the-ai-revolution-in-human-computer-interaction-embracing-collaborative-intelligence-e063dedeb49f">qui</a>.
            </div>
        }
    ),
    new NewsItem(
        "27/09/2023",
        {"en": "New article 'Fine-Tuning a Generative Model on My Art: an Experiment with DreamBooth'", "it": "Nuovo articolo 'Fine-Tuning a Generative Model on My Art: an Experiment with DreamBooth'"},
        {
            "en": 
            <div>
                I published an article on Medium, its title is 'Fine-Tuning a Generative Model on My Art: an Experiment with DreamBooth'. 
                I shared my experiment with fine-tuning a generative model on my own art style using DreamBooth. 
                If you want to discover the results, understand how this innovative approach has empowered my creative process and how I gained insights into the core features of my art, 
                read the full article <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@sarasisti.mi/fine-tuning-a-generative-model-on-my-art-an-experiment-with-dreambooth-814947a5e3fb">here</a>! 
            </div>, 
            "it": 
            <div>
                Ho pubblicato un articolo su Medium, il cui titolo è 'Fine-Tuning a Generative Model on My Art: an Experiment with DreamBooth'. 
                Ho condiviso la mia esperienza nel perfezionare un modello generativo sul mio stile artistico utilizzando DreamBooth. Se desideri scoprire i risultati, 
                comprendere come questo approccio innovativo ha potenziato il mio processo creativo e come ho acquisito insights sulle caratteristiche principali della 
                mia arte, leggi l'articolo completo <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@sarasisti.mi/fine-tuning-a-generative-model-on-my-art-an-experiment-with-dreambooth-814947a5e3fb">qui</a>!
            </div>
        }
    ),
    new NewsItem(
        "12/12/2023",
        {"en": "New article 'Generative Artificial Intelligence: Thoughts and Considerations on a New Artistic Medium'", "it": "Nuovo articolo 'Generative Artificial Intelligence: Thoughts and Considerations on a New Artistic Medium'"},
        {
            "en": 
            <div>
                I published an article on Medium, its title is 'Generative Artificial Intelligence: Thoughts and Considerations on a New Artistic Medium'. 
                I’ve tried to sum up my thoughts and considerations on Generative Artificial Intelligence as a new artistic medium and its implications: I 
                drew comparisons between AI and other artistic mediums and listed some examples about the most exciting ways, at least in my opinion, 
                to interact creatively with this new tool. Read more <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@sarasisti.mi/generative-artificial-intelligence-thoughts-and-considerations-on-a-new-artistic-medium-9a0610f10c78">here</a>
            </div>, 
            "it": 
            <div>
                Ho pubblicato un articolo su Medium, il cui titolo è '"The AI Revolution in Human-Computer Interaction: Embracing Collaborative Intelligence"'. 
                Ho cercato di riassumere i miei pensieri e considerazioni sull'Intelligenza Artificiale Generativa come nuovo medium artistico e le sue implicazioni.
                 Ho tracciato confronti tra l'IA e altri mezzi artistici e ho elencato alcuni esempi delle modalità più entusiasmanti, almeno secondo la mia opinione, 
                 per interagire creativamente con questo nuovo strumento. Leggi di 
                 più <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@sarasisti.mi/generative-artificial-intelligence-thoughts-and-considerations-on-a-new-artistic-medium-9a0610f10c78">qui</a>.
            </div>
        }
    ),
]