import  React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../domain/icons/icons8-arrow-32.svg";
import { DEFAULT_CATEGORY } from "../../domain/enums/DefaultCategory";
import { AppContext } from "../context/AppContext";
import { HashLink } from "react-router-hash-link";

class DropDown extends React.Component {
  static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        showDropDownMenu: false,
      };

      this.ref = React.createRef(this);
    }

    closeAllMenus = () => {
      this.closeMenu();
      if (this.props.closeMenu) this.props.closeMenu();
    }

    toggleMenu = () => {
      this.setState({ showDropDownMenu: !this.state.showDropDownMenu });
    };
    
    closeMenu = () => {
      this.setState({ showDropDownMenu: false });
    };

    openMenu = () => {
      this.setState({ showDropDownMenu: true });
    };

    render() {
      const {language, isMobile} = this.context;
      const main_cat = this.props.subcategories.find(cat => {return cat.title === DEFAULT_CATEGORY});

      return (
        <div ref={this.ref} onMouseEnter={this.openMenu} onMouseLeave={this.closeMenu}>
        <p className="navelement" onClick={this.toggleMenu}> 
        <Link to={main_cat.link}>{main_cat.get_translated_title(language)}<ArrowIcon className="arrow-icon" /> </Link>
        </p>
        {this.state.showDropDownMenu && (
          <div className="submenu">
            {this.props.subcategories.map((cat, index) => (  
            <p key={index} className="submenuelement" >
              {
                index == 0 ?
                <Link 
                  to={main_cat.link} 
                  onClick={this.closeAllMenus}>
                    {cat.get_translated_title(language)}
                </Link> :
                <HashLink 
                  scroll={(el) => {
                    el.scrollIntoView({ behavior: 'auto', block: 'start' });
                    if (isMobile) window.scrollBy(0, -122);
                    else window.scrollBy(0, -15);
                  }}
                  to={main_cat.link + "/#" + `${main_cat.link.replace("/", "")}${index}`} 
                  onClick={this.closeAllMenus}>
                    {cat.get_translated_title(language)}
                </HashLink>
              }
            </p> 
            ))}  
          </div>
        )}
      </div>
      );
    }
  }

export default DropDown;