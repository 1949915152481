import React, { useState, useContext } from 'react';
import { AppContext } from './context/AppContext';
import itFlag from "../domain/icons/it.png";
import enFlag from "../domain/icons/en.png";
import esFlag from "../domain/icons/es.png";
import { ReactComponent as ArrowIcon } from "../domain/icons/icons8-arrow-32.svg";

const LanguageSelector = (props) => {
    const { language, changeLanguage } = useContext(AppContext);
    const [showOptions, setShowOptions] = useState(false);
    const [showCurrent, setShowCurrent] = useState(true);
  
    const handleLanguageChange = (event) => {
      const selectedLanguage = event.target.alt;

      setShowCurrent(true);
      changeLanguage(selectedLanguage);

      setShowOptions(false);
      closeAllMenus();
    };
  
    const handleFlagClick = () => {
      setShowOptions(!showOptions);
      setShowCurrent(!showCurrent);
    };

    const closeAllMenus = () => {
      if (props.closeMenu) props.closeMenu();
    }

    const italianOption = <div>
      <img width="20px" height="20px" src={itFlag} alt="it" onClick={handleLanguageChange} />
    </div>;
    const englishOption = <div>
      <img width="20px" height="20px" src={enFlag} alt="en" onClick={handleLanguageChange} />
    </div>;
  
    return (
        <div className='language-select'>
          <div onClick={handleFlagClick}>
          {showCurrent && <div>
            {language === 'it' && <img width="20px" height="20px" src={itFlag} alt="it" />}
            {language === 'en' && <img width="20px" height="20px" src={enFlag} alt="en" />}
            {/* {language === 'es' && <img width="20px" height="20px" src={esFlag} alt="es" />} */}
          </div>}
          <ArrowIcon className="arrow-icon-language" />
          </div>
          {showOptions && (
            <div className='language-options'>
              {language === 'it' && <>{italianOption}{englishOption}</>}
              {language === 'en' && <>{englishOption}{italianOption}</>}
              {/* <div>
                <img width="20px" height="20px" src={esFlag} alt="es" onClick={handleLanguageChange} />
              </div> */}
            </div>
          )}
        </div>
    );
  };

export default LanguageSelector;
