import { Link } from "react-router-dom";
import { ArtGalleryCollection } from "./models/ArtGalleryCollection.ts";
import doors_of_perc_1 from "./images/art/1_the doors of perception.png";
import doors_of_perc_2 from "./images/art/2_One cannot escape the mystery of consciousness.png";
import doors_of_perc_3 from "./images/art/3_the mystery of consciousness.png";
import doors_of_perc_4 from "./images/art/4_every human group is a society of island universes.png";
import doors_of_perc_5 from "./images/art/5_if the doors of perception were cleansed everything would appear to man as it is, infinite.png";
import doors_of_perc_6 from "./images/art/6_self-trancendence.png";
import doors_of_perc_7 from "./images/art/7_the mind is its own place.png";
import doors_of_perc_8 from "./images/art/8_there are things known and there are things unknown, and in between are the doors of perception.png";
import doors_of_perc_9 from "./images/art/9_each one of us is potentially mind at large.png";
import doors_of_perc_10 from "./images/art/10_to be shaken out of the ruts of ordinary perception.png";
import mask_1 from "./images/art/249756744_a_mind_blowing_and_sinuous_sculpture_of_a_mask_of_a_hallucination_monster_made_with_ceramics__psychedelic_colors_and_texture (1).png";
import mask_2 from "./images/art/264786469_a_mind_blowing_and_sinuous_sculpture_of_a_mask_of_a_hallucination_monstrous_god_made_with_ceramics__psychedelic_colors_and_texture (1).png";
import mask_3 from "./images/art/1551328507_a_mind_blowing_and_sinuous_sculpture_of_a_mask_of_a_hallucination_god_made_with_ceramics__psychedelic_colors_and_texture (1).png"
import mask_4 from "./images/art/1819759464_a_mind_blowing_and_sinuous_sculpture_of_a_mask_of_a_hallucination_god_made_with_ceramics__psychedelic_colors_and_texture (1).png";
import mask_5 from "./images/art/2362325379_a_mind_blowing_and_sinuous_sculpture_of_a_mask_of_a_hallucination_god_made_with_ceramics__psychedelic_colors_and_texture (1).png";
import mask_6 from "./images/art/3233959776_a_mind_blowing_and_sinuous_sculpture_of_a_mask_of_a_hallucination_alien_made_with_ceramics__psychedelic_colors_and_texture (1).png";
import mask_7 from "./images/art/3986283484_a_mind_blowing_and_sinuous_sculpture_of_a_mask_of_a_hallucination_monster_made_with_ceramics__psychedelic_colors_and_texture (1).png";
import sun from "./images/art/sun.png";
import moon from "./images/art/moon.png";
import flower_cr from "./images/art/flower_cr.png";
import costa_rica_flower_2 from "./images/art/costa_rica_flower_2.png";
import sloth from "./images/art/sloth.png";
import colibri from "./images/art/colibri.png";
import cat1 from "./images/art/cat1.png";
import cat2 from "./images/art/cat2.png";
import fish1 from "./images/art/fish1.png";
import fish4 from "./images/art/fish4.png";
import flowers1 from "./images/art/flowers1.png";
import flowers2 from "./images/art/flowers2.png";
import mushroom1 from "./images/art/mushroom1.png";
import mushroom2 from "./images/art/mushroom2.png";
import pattern1 from "./images/art/pattern1.png";
import pattern3 from "./images/art/pattern3.png";
import costarica_video1 from "./images/art/costarica_video1.mp4";
import costarica_video2 from "./images/art/costarica_video2.mp4";
import model1_1 from "./images/art/model1_1.jpeg";
import model1_2 from "./images/art/model1_2.jpeg";
import model1_3 from "./images/art/model1_3.jpeg";
import model1_4 from "./images/art/model1_4.jpeg";
import model2_1 from "./images/art/model2_1.jpeg";
import model2_2 from "./images/art/model2_2.jpeg";
import model2_3 from "./images/art/model2_3.jpeg";
import model2_4 from "./images/art/model2_4.jpeg";

export const ArtGalleryCollections = [
    new ArtGalleryCollection(
      "1",
      [doors_of_perc_1, doors_of_perc_2, doors_of_perc_3, doors_of_perc_4, doors_of_perc_5, doors_of_perc_6, doors_of_perc_7, doors_of_perc_8, doors_of_perc_9, doors_of_perc_10], 
      [doors_of_perc_1, doors_of_perc_2, doors_of_perc_3, doors_of_perc_4, doors_of_perc_5, doors_of_perc_6, doors_of_perc_7, doors_of_perc_8, doors_of_perc_9, doors_of_perc_10], 
      {"en": "The doors of perception", "it": "Le porte della percezione"}, 
      {
        "en": 
          <div>
            This is a collection of artworks inspired by Aldous Huxley's iconic book 'The Doors Of Perception'. 
            I created it by experimenting with generative neural networks. The collection is made of 10 pieces. 
            Each piece is unique and is inspired by a quote from the book, or from a re-interpreted concept found in it. 
            Huge thanks to <a href="https://twitter.com/RiversHaveWings" target="_blank" rel="noopener noreferrer"> RiversHaveWings</a> and 
            <a href="https://twitter.com/advadnoun" target="_blank" rel="noopener noreferrer"> advadnoun</a> for the code I used to generate this artworks.
          </div>, 
        "it": 
          <div>
            Questa è una collezione di opere ispirate all'iconico libro di Aldous Huxley, 'Le Porte della Percezione'. L'ho creata sperimentando con reti neurali generative. 
            La collezione è composta da 10 pezzi. Ogni pezzo è unico ed è ispirato a una citazione dal libro o a un concetto reinterpretato trovato al suo interno. 
            Un enorme ringraziamento a <a href="https://twitter.com/RiversHaveWings" target="_blank" rel="noopener noreferrer"> RiversHaveWings</a> e 
            <a href="https://twitter.com/advadnoun" target="_blank" rel="noopener noreferrer"> advadnoun</a> per il codice che ho utilizzato per generare queste opere.
          </div>
      }, 
      "Digital Art", "1920px x 1920px", "2020", 
      {"en": "AI Generated", "it": "Generato da IA"}, 
      {"en": "The Doors Of Perception", "it": "Le Porte Della Percezione"}, 
      undefined, undefined),
    new ArtGalleryCollection(
      "2",
      [mask_1, mask_2, mask_3, mask_4, mask_5, mask_6, mask_7], 
      [mask_1, mask_2, mask_3, mask_4, mask_5, mask_6, mask_7], 
      {"en": "AI Generated Designs of a Mask", "it": "Design di una Maschera Generati da AI"}, 
      {
        "en": 
          <div>
            This images have been generated with 
            <a href="https://beta.dreamstudio.ai" target="_blank" rel="noopener noreferrer"> DreamStudio</a>, to prototype the design of a mask I made with ceramics afterwards. 
            <Link to="/art/23"> This</Link> is the final result in ceramic.
          </div>,
        "it": 
          <div>
            Queste immagini sono state generate con 
            <a href="https://beta.dreamstudio.ai" target="_blank" rel="noopener noreferrer"> DreamStudio</a>, 
            per prototipare il design di una maschera che ho poi realizzato in ceramica. 
            <Link to="/art/23"> Questo</Link> è il risultato finale in ceramica.
          </div>
      },
        "Digital Art", "512px x 512px", "2022",   
      {"en": "AI Generated", "it": "Generato da IA"}, 
      {"en": "Masks Design", "it": "Masks Design"}, 
      undefined, undefined
    ),
    new ArtGalleryCollection(
      "3",
      [sun, moon], 
      [sun, moon], 
      {"en": "Sun and moon", "it": "Sole e luna"}, 
      {
        "en": "Pair of small-sized paintings depicting the sun and the moon.",
        "it": "Coppia di quadri di piccole dimensioni che raffigurano il sole e la luna."
      },
        "Paintings", "12cm x 12cm", "2021",   
      {"en": "Acrylics on canva", "it": "Acrilici su tela"}, 
      {"en": "Small paintings", "it": "Small paintings"}, 
      undefined, undefined
    ),
    new ArtGalleryCollection(
      "4",
      [flower_cr, sloth, colibri, costa_rica_flower_2], 
      [flower_cr, sloth, colibri, costa_rica_flower_2], 
      {"en": "Costa Rican memories", "it": "Ricordi Costa Ricani"}, 
      {
        "en": "I sketched some flora and fauna that I had photographed during my 2023 trip to Costa Rica.",
        "it": "Ho disegnato alcuni esemplari di flora e fauna che ho fotografato durante il mio viaggio in Costa Rica del 2023."
      },
        "Paintings", "20cm x 15cm", "2023",   
      {"en": "Sketches on notebook", "it": "Disegni su taccuino"}, 
      {"en": "Costa Rica", "it": "Costa Rica"}, 
      undefined, undefined
    ),
    new ArtGalleryCollection(
      "5",
      [cat1, cat2, fish1, fish4, flowers1, flowers2, mushroom1, mushroom2, pattern1, pattern3], 
      [cat1, cat2, fish1, fish4, flowers1, flowers2, mushroom1, mushroom2, pattern1, pattern3], 
      {"en": "Generative AI trained on my art", "it": "AI Generativa allenata sulla mia arte"}, 
      {
        "en": <div>
          I trained an AI model on my art and then used the customized model to generate some images. These are some examples. 
          I also described how I fine-tuned the model in 
          <a href="https://medium.com/@sarasisti.mi/fine-tuning-a-generative-model-on-my-art-an-experiment-with-dreambooth-814947a5e3fb" target="_blank" rel="noopener noreferrer">this Medium article</a>.
        </div>,
        "it":  <div>
        Ho allenato un modello di IA generativa sulla mia arte e poi usato il modello custom per generare immagini. Questi sono alcuni esempi.
        Ho anche descritto come ho eseguito il fine-tuning sul modello in 
        <a href="https://medium.com/@sarasisti.mi/fine-tuning-a-generative-model-on-my-art-an-experiment-with-dreambooth-814947a5e3fb" target="_blank" rel="noopener noreferrer">questo articolo su Medium</a>.
      </div>
      },
        "Digital Art", "600px x 600px", "2023",   
      {"en": "AI Generated", "it": "Generato da IA"}, 
      {"en": "fine-tuned-model", "it": "fine-tuned-model"}, 
      undefined, undefined
    ),
    new ArtGalleryCollection(
      "6",
      [costarica_video1, costarica_video2], 
      [costarica_video1, costarica_video2], 
      {"en": "Costa Rica - Touchdesigner", "it": "Costa Rica - Touchdesigner"}, 
      {
        "en": "I used Touchdesigner and some sketches to create this visual. I took the background photos on a trip to Costa Rica.",
        "it":  "Ho utilizzato Touchdesigner e dei disegni per creare questo visual. Le fotografie di sfondo sono state scattate da me durante un viaggio in Costa Rica."
      },
        "Digital Art", "1248px x 1280px", "2024",   
      {"en": "Sketch + Touchdesigner", "it": "Disegno + Touchdesigner"}, 
      {"en": "touchdesigner", "it": "touchdesigner"}, 
      undefined, undefined
    ),
    new ArtGalleryCollection(
      "7",
      [model1_1, model1_2, model1_3, model1_4], 
      [model1_1, model1_2, model1_3, model1_4], 
      {"en": "Exactly.ai - on my art", "it": "Exactly.ai - sulla mia arte"}, 
      {
        "en": "I fine-tuned a generative AI model on my art with Exactly.ai platform. These are some images I generated with that model",
        "it":  "Ho personalizzato un modello di intelligenza artificiale generativa facendo fine-tuning sulla mia arte con la piattaforma Exactly.ai. Queste sono alcune immagini generate con quel modello."
      },
        "Digital Art", "1080px x 1620px", "2024",   
      {"en": "AI Generated", "it": "Generato da IA"}, 
      {"en": "fine-tuned-model", "it": "fine-tuned-model"}, 
      undefined, undefined
    ),
    new ArtGalleryCollection(
      "8",
      [model2_1, model2_2, model2_3, model2_4], 
      [model2_1, model2_2, model2_3, model2_4], 
      {"en": "Exactly.ai - on my art", "it": "Exactly.ai - sulla mia arte"}, 
      {
        "en": "I fine-tuned a generative AI model on some photos, distorted through Touchdesigner, with Exactly.ai platform. These are some images I generated with that model",
        "it":  "Ho personalizzato un modello di intelligenza artificiale generativa facendo fine-tuning su alcune fotografie, distorte con Touchdesigner, tramite la piattaforma Exactly.ai. Queste sono alcune immagini generate con quel modello."
      },
        "Digital Art", "1080px x 1620px", "2024",   
      {"en": "AI Generated", "it": "Generato da IA"}, 
      {"en": "fine-tuned-model", "it": "fine-tuned-model"}, 
      undefined, undefined
    )
];