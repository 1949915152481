import React, { useContext } from 'react';
import Title from "./Title";
import NavBar from "./navbar/Navbar";
import { AppContext } from './context/AppContext';
import HamburgerMenu from './HamburgerMenu';

class Header extends React.Component {
    static contextType = AppContext;
  
    render() {
      const { isMobile } = this.context;
      const { isGlitchActive } = this.props;

      return (
        <div className={`header ${isGlitchActive && !isMobile ? 'glitch' : ''}`}>
          <Title title="Sara Sisti" subtitle="Software & AI Developer, Artist"/>
          {isMobile}
          { isMobile ? <HamburgerMenu /> : <NavBar /> }
        </div>
      );
    }
  }

export default Header;