export class TechEducation {
    from: string;
    to: string;
    where: object;
    description: object;
    vote: string | undefined;
    thesis_title: object | undefined;
    thesis_url: string | undefined;
    
    constructor(from: string, to: string, where: object, description: object, vote: string | undefined, 
        thesis_title: object | undefined, thesis_url: string | undefined) {
        this.from = from;
        this.to = to;
        this.where = where;
        this.description = description;
        this.vote = vote;
        this.thesis_title = thesis_title;
        this.thesis_url = thesis_url;
    }

    get_translated_where(language: string) {
        if (!(language in this.where)) language = "en";
        
        return this.where[language];
    }

    get_translated_description(language: string) {
        if (!(language in this.description)) language = "en";
        
        return this.description[language];
    }

    get_translated_thesis_title(language: string) {
        if (this.thesis_title == undefined) return;

        if (!(language in this.thesis_title)) language = "en";
        
        return this.thesis_title[language];
    }
}