import React from 'react';
import get_translated_content from "../../domain/languages/get_translated_content.js";

const TechEducationComponent = ({ el, language }) => {
  return (
    <div className="tech-education tech-subcategory">
      <div className='tech-timeline'></div>
      <div className='tech-timeline-point'></div>
      <div className='tech-subcategory-left'>
        <strong>{el.from} - {el.to}</strong> <br></br>
        <strong>{el.get_translated_where(language)}</strong> <br></br>
      </div>
      <div className='tech-subcategory-right'>
        {el.vote != null && (<span><strong>{get_translated_content(language, "tech.education.grade")}</strong> {el.vote}<br></br></span>)}
        {el.thesis_url && (
          <span><strong>{get_translated_content(language, "tech.education.thesis")}</strong>
            <a href={el.thesis_url} target="_blank" rel="noreferrer"> {el.get_translated_thesis_title(language)}</a><br></br>
          </span>
        )}
        <p>{el.get_translated_description(language)}</p>
      </div>
    </div>
  );
};

export default TechEducationComponent;
