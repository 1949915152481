import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/Home";
import About from "../pages/About";
import Art from "../pages/Art";
import Tech from "../pages/Tech";
import Writing from "../pages/Writing";
import Contact from "../pages/Contact";
import ArtSubCategories from "../domain/ArtSubCategories";
import TechSubCategories from "../domain/TechSubCategories";
import { AppContext } from "./context/AppContext";
import { DEFAULT_CATEGORY } from "../domain/enums/DefaultCategory";
import { ArtGalleryItems } from "../domain/ArtGalleryItems";
import GalleryItemDetail from "./gallery/GalleryItemDetail";
import GalleryCollectionDetail from "./gallery/GalleryCollectionDetail";
import { SectionsRoutes } from "../domain/enums/SectionsRoutes.ts";

class Main extends React.Component {
  static contextType = AppContext;

    render() {
      const { isMobile } = this.context;
      const { isGlitchActive } = this.props;

      let content = (
        <Routes>
          <Route path={SectionsRoutes.HOME} element={<HomePage isGlitchActive={isGlitchActive} />} />
          <Route path={SectionsRoutes.ABOUT} element={<About isGlitchActive={isGlitchActive}/>} />
          <Route path={SectionsRoutes.ART} element=
            {<Art isGlitchActive={isGlitchActive}/>}>
            <Route 
              index
              path={':mediaId'} 
              element={<GalleryItemDetail />} 
              />
            <Route 
              index
              path={'collections/:mediaId'} 
              element={<GalleryCollectionDetail />} 
              />
          </Route>
          <Route path={SectionsRoutes.TECH} element=
            {<Tech isGlitchActive={isGlitchActive} />}/>
          {/* <Route path='/writing' element={<Writing isGlitchActive={isGlitchActive}/>} /> */}
          <Route path={SectionsRoutes.CONTACT} element={<Contact isGlitchActive={isGlitchActive}/>} />
        </Routes>
      );

      
      return ( 
        <div className="mobile-under-sticky">
        {content}
        </div>
      );
    }
  }

export default Main;