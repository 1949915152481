import React, { useState, useContext } from "react";
import Glitch from "./easter_eggs/Glitch";
import { ReactComponent as MailIcon } from "../domain/icons/mail-142.svg";
import { ReactComponent as GithubIcon } from "../domain/icons/iconmonstr-github-1.svg";
import { ReactComponent as LinkedinIcon } from "../domain/icons/iconmonstr-linkedin-3.svg";
import { AppContext } from "./context/AppContext.js";
import FooterMobileNavigator from "./FooterMobileNavigator";

const Footer = ({ isGlitchActive, onGlitchHover }) => {
  const { isMobile } = useContext(AppContext);

  return (
    <div className="footer">
      {isMobile && <FooterMobileNavigator />}
      <div className="contacts-footer">
        <a href="mailto:sarasisti.mi@gmail.com" target="_blank" rel="noopener noreferrer" title="mail">
          <div className="social">
            <MailIcon /> 
            <p>sarasisti.mi@gmail.com</p>
          </div>
        </a>

        <div>
          <a href="https://github.com/sarasixti" target="_blank" rel="noopener noreferrer" className="social-icon" title="github">
            <GithubIcon /> 
          </a>
          <a style={{
              margin: `15px`
            }} href="https://www.linkedin.com/in/sara-sisti-b169a1141/" target="_blank" rel="noopener noreferrer" className="social-icon" title="linkedin">
            <LinkedinIcon /> 
          </a>
        </div>
      </div>

      {/* <Glitch isGlitchActive={isGlitchActive} onHover={onGlitchHover} /> */}
    </div>
  );
};

export default Footer;
