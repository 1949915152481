const esTranslations = {
    "home.title": "Home",
    "home.content": "¡Bienvenido a nuestro sitio web!",
    "about.title": "About",
    "about.content": "About content es",
    "writing.title": "Writing",
    "writing.content": "Writing content es",
    "contact.title": "Contáctame",
    "contact.content": "Puedes contactarme a través de los siguientes canales:",
    "share.title": "Share",
    "copied": "Copiado!"
  }

export default esTranslations;
  