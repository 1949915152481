import React from "react";
import { AppContext } from "../context/AppContext";
import Canvas from "../Canvas";

class GalleryItem extends React.Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = { isLoading: true };

    }

    componentDidMount() {
        this.loadMedia();
    }

    loadMedia() {
      const { media, media_thumbnail } = this.props.item;
      if (media === "canvas") this.setState({ isLoading: false });
      
      if (typeof media_thumbnail === "string" && media.endsWith(".mp4") ) {
        this.setState({ isLoading: false });
      } 

      const image = new Image();
  
      image.onload = () => {
        this.setState({ isLoading: false });
      };
  
      image.src = media_thumbnail || media;
    }
  
    render() {
      const { isMobile, language } = this.context;

      const { thumbnail_size } = this.props;
      const { media_thumbnail, media } = this.props.item;
      const media_title=this.props.item.get_translated_title(language);
    
      return (
        <>
          <div className="galleryitem"
            style={{
              width: `${thumbnail_size}px`,
              height: `${thumbnail_size}px`
            }} >
            {this.state.isLoading && !isMobile ? (
              // Show loading animation if isLoading is true
              <div
                className="loading-background"
                style={{
                  width: `${thumbnail_size}px`,
                  height: `${thumbnail_size}px`
                }}
              >
                <div className="center-loading">
                  <div className="lds-dual-ring"></div>
                </div>
              </div>
            ) : (
              <>
                {typeof media_thumbnail === "string" && media.endsWith(".mp4") ? (
                  <video width={thumbnail_size} height={thumbnail_size} autoPlay muted loop playsInline className="galleryitem-media">
                    <source src={media_thumbnail} type="video/mp4" />
                  </video>
                ) : typeof media_thumbnail === "string" && media === "canvas" ? (
                  <div 
                    style={{
                      width: `${thumbnail_size}px`,
                      height: `${thumbnail_size}px`
                    }} 
                  className="galleryitem-media"><Canvas draw={this.props.draw} animate={this.props.animate} width={thumbnail_size} height={thumbnail_size}/></div>
                ) : (
                  <img src={media_thumbnail} alt={media_title} width={thumbnail_size} height={thumbnail_size} className="galleryitem-media" />
                )}
              </>
            )}
          </div>
        </>
      );
    }
  }

export default GalleryItem;