export class TechCertification {
    title: string;
    link: string | undefined;
    image: string | undefined;
    
    constructor(title: string, link: string | undefined, image: string | undefined) {
        this.title = title;
        this.link = link;
        this.image = image;
    }
}