import React from "react";
import { AppContext } from "../context/AppContext";
import NewsCard from "./NewsCard";
import { NewsItems } from "../../domain/NewsItems";
import { compareDates } from "../../utils/compare_dates";
import { ReactComponent as ArrowLeftIcon } from "../../domain/icons/icons8-arrow-32-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../domain/icons/icons8-arrow-32-right.svg";

class News extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      scrollPosition: 0,
      maxScrollPosition: 0,
      canScrollLeft: false,
      canScrollRight: false,
      isScrolling: false,
      scrollDirection: "",
    };
    this.newsContentRef = React.createRef();
  }

  componentDidMount() {
    this.updateMaxScrollPosition();
    window.addEventListener("resize", this.updateMaxScrollPosition);
    this.checkScrollable();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMaxScrollPosition);
    this.clearScrollInterval();
  }

  updateMaxScrollPosition = () => {
    const { clientWidth, scrollWidth } = this.newsContentRef.current;
    const maxScrollPosition = scrollWidth - clientWidth;
    this.setState({ maxScrollPosition }, this.checkScrollable);
  };

  checkScrollable = () => {
    const { scrollPosition, maxScrollPosition } = this.state;
    this.setState({
      canScrollLeft: scrollPosition > 0,
      canScrollRight: scrollPosition < maxScrollPosition,
    });
  };

  handleMouseDown = (direction) => {
    this.setState({ isScrolling: true, scrollDirection: direction });
    this.startContinuousScroll();
  };

  handleMouseUp = () => {
    this.setState({ isScrolling: false });
    this.clearScrollInterval();
  };

  handleMouseLeave = () => {
    if (this.state.isScrolling) {
      this.setState({ isScrolling: false });
      this.clearScrollInterval();
    }
  };

  handleMouseMove = () => {
    if (this.state.isScrolling) {
      this.startContinuousScroll();
    }
  };

  handleMouseEnter = (direction) => {
    this.setState({ isScrolling: true, scrollDirection: direction });
    this.startContinuousScroll();
  };

  handleTouchStart = (direction) => {
    this.setState({ isScrolling: true, scrollDirection: direction });
    this.startContinuousScroll();
  };

  handleTouchEnd = () => {
    this.setState({ isScrolling: false });
    this.clearScrollInterval();
  };

  handleTouchCancel = () => {
    if (this.state.isScrolling) {
      this.setState({ isScrolling: false });
      this.clearScrollInterval();
    }
  };

  handleScroll = (direction) => {
    const { scrollPosition, maxScrollPosition } = this.state;
    const scrollIncrement = 1; // Adjust this value based on your preference

    const newsContent = this.newsContentRef.current;

    if (direction === "left" && scrollPosition > 0) {
      const newScrollPosition = Math.max(0, scrollPosition - scrollIncrement);
      this.setState({ scrollPosition: newScrollPosition }, this.checkScrollable);
      newsContent.scrollLeft = newScrollPosition;
    } else if (direction === "right" && scrollPosition < maxScrollPosition) {
      const newScrollPosition = Math.min(maxScrollPosition, scrollPosition + scrollIncrement);
      this.setState({ scrollPosition: newScrollPosition }, this.checkScrollable);
      newsContent.scrollLeft = newScrollPosition;
    }
  };

  startContinuousScroll = () => {
    this.clearScrollInterval();
    this.scrollInterval = setInterval(() => {
      const { scrollDirection } = this.state;
      this.handleScroll(scrollDirection);
    }, 10); // Adjust the interval for smoother scrolling
  };

  clearScrollInterval = () => {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  };

  render() {
    const { language } = this.context;
    const { canScrollLeft, canScrollRight } = this.state;

    const news = NewsItems;
    news.sort((item1, item2) => compareDates(item1.date, item2.date));

    return (
      <div className="news-container">
        <div className="news-title">News</div>
        <div className="news-content-arrows">
          {canScrollLeft && (
            <ArrowLeftIcon
              className="arrow-left-icon news-arrow"
              onMouseDown={() => this.handleMouseDown("left")}
              onMouseUp={this.handleMouseUp}
              onMouseLeave={this.handleMouseLeave}
              onMouseMove={this.handleMouseMove}
              onMouseEnter={() => this.handleMouseEnter("left")}
              onTouchStart={() => this.handleTouchStart("left")}
              onTouchEnd={this.handleTouchEnd}
              onTouchCancel={this.handleTouchCancel}
            />
          )}
          <div
            className="news-content"
            ref={this.newsContentRef}
            onScroll={this.checkScrollable}
            style={{ overflowX: "auto" }}
          >
            {news.map((item, index) => (
              <NewsCard news_item={item} key={index} />
            ))}
          </div>
          {canScrollRight && (
            <ArrowRightIcon
              className="arrow-right-icon news-arrow"
              onMouseDown={() => this.handleMouseDown("right")}
              onMouseUp={this.handleMouseUp}
              onMouseLeave={this.handleMouseLeave}
              onMouseMove={this.handleMouseMove}
              onMouseEnter={() => this.handleMouseEnter("right")}
              onTouchStart={() => this.handleTouchStart("right")}
              onTouchEnd={this.handleTouchEnd}
              onTouchCancel={this.handleTouchCancel}
            />
          )}
        </div>
      </div>
    );
  }
}

export default News;
