import React from "react";
import { AppContext } from "../context/AppContext";
import NewsDetail from "./NewsDetail";

class NewsCard extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = { showDetail: false };
        this.closeDetail = this.closeDetail.bind(this);
        this.handleCardClick = this.handleCardClick.bind(this);
      }

    handleCardClick() {
        this.setState({ showDetail: true });
    };

    closeDetail() {
        this.setState({ showDetail: false });
    }

    render() {
        const { language } = this.context;
        const { news_item } = this.props;

        return (
            <div>
                <div className="news-card" onClick={this.handleCardClick}>
                    <div className="news-card-date">{news_item.date}</div>
                    <div className="news-card-title">{news_item.get_translated_title(language)}</div>
                    <div className="news-card-description">{news_item.get_translated_description(language)}</div>
                </div>
                <div className="news-card-dots">...</div>
                {this.state.showDetail && <NewsDetail news_item={news_item} onClose={this.closeDetail} />}
            </div>
        );
    }
}

export default NewsCard;