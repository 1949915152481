import enTranslations from "./en";
import itTranslations from "./it";
import esTranslations from "./es";

const get_translated_content = (language, key) => {
    switch(language) {
        case "en":
            return enTranslations[key];
        case "it":
            return itTranslations[key]; 
        case "es":
            return esTranslations[key];
            
        default:
            return enTranslations[key];
    }
}

export default get_translated_content;