import { TechProject } from "./models/TechProject.ts";
import recsys_project from "./pdfs/RS_report.pdf";

const TechProjects = [
    new TechProject({"en": "This very website", "it": "Questo stesso sito"}, 
        {"en": "I've designed and coded this website using  React, JavaScript, TypeScript, HTML and CSS. This is the very first website I coded on my own.", "it": "Ho progettato e programmato questo sito web utilizzando React, JavaScript, TypeScript, HTML e CSS. Questo è il primo sito web che ho programmato da sola."}, 
        window.location.origin, "Sara Sisti", "2023"),
    new TechProject({"en": "European history 'in a nutshell'", "it": "La storia Europea in poche parole"}, 
        {"en": "A university project for the Statistical Learning course. The objective was the analysis and comparison of the history of the most important European cities. We used web scraping to download the content from the Wikipedia pages of the capitals, and then employed various text mining algorithms to automatically extract information from the texts. Finally, we created impactful visualizations to present the results. The entire project was conducted using R, and the web app was built using Shiny (an R library).", "it": "Un progetto universitario per il corso di Statistical Learning. L'obiettivo era l'analisi e il confronto della storia delle piu' importanti citta' europee. Abbiamo utilizzato web scraping per scaricare i contenuti delle pagine wikipedia delle capitali, e poi utilizzato diversi algoritmi di text mining per estrarre in modo automatico le informazioni da testi e infine creato delle visualizzazioni di impatto per presentare i risultati. Il tutto è stato svolto utilizzando R e la webapp è stata creata usando Shiny (libreria R). "}, 
        "https://martinacaronte.shinyapps.io/projectsl2/", "Sara Sisti, Martina Caronte, Federica Lo Giudice", "2019"),
    new TechProject({"en": "A Recommender System for restaurants", "it": "Un Recommender System per ristoranti"}, 
        {"en": "This is a university project for Machine Learning course. My team and I attempted to create a recommendation system that would suggest 10 restaurants to each user in the dataset based on their previous preferences. We performed both exploratory data analysis and a comparison of various standard recommendation system algorithms. Finally, we implemented a multidimensional recommendation technique.", "it": "Un progetto universitario per il corso di Machine Learning. Io e il mio team abbiamo tentato di realizzare un sistema di raccomandazione che suggerisse 10 ristoranti ad ogni utente del dataset, sulla base delle loro preferenze precedenti. Abbiamo svolto sia un'analisi esplorativa dei dati, sia un confronto su diversi algoritmi di sistemi di raccomandazione standard. Infine è stata implementata una tecnica di raccomandazione multidimensionale. "}, 
        recsys_project, "Sara Sisti, Martina Caronte, Elena Bagnati", "2018"),
]

export default TechProjects;