import { TechEducation } from "./models/TechEducation.ts";
import tesi_magistrale from "./pdfs/tesi_magistrale.pdf";
import tesi_triennale from "./pdfs/tesi_triennale.pdf";

const TechEducations = [
    new TechEducation(
        "07.2019", 
        "09.2019", 
        {"en": "BCAMP - Bending Spoons (Milan, Italy)", "it": "BCAMP - Bending Spoons (Milano)"},
        {   "en": "Intensive 5 week programming bootcamp about the swift programming language and IOS development. At the end of the course my classmates and I developed a photo editing mobile app as final project. ", 
            "it": "Intensivo bootcamp di programmazione della durata di 5 settimane sul linguaggio di programmazione Swift e lo sviluppo iOS. Alla fine del corso io e i miei compagni di classe abbiamo sviluppato un'app mobile per il photo editing come progetto finale."
        }
    ),
    new TechEducation(
        "09.2017", 
        "07.2019", 
        {"en": "Master's degree, Statistics and data science (University of Milan - Bicocca)", "it": "Laurea Magistrale, Statistica e data science (Università degli Studi di Milano-Bicocca)"},
        {   "en": (
            <span>The degree focused on both theoretical and practical lessons about the following topics:
                    <li>Advanced data analysis and mining techniques</li>
                    <li>Machine Learning, Text mining, Signal and image management and analysis</li>
                    <li>Bayesian statistics</li>
                    <li>Stochastic processes</li>
                    <li>Spatial Statistics</li>
                    <li>Advanced time series analysis techniques</li>
                    <li>Simulation techniques</li>
            </span>
        ), 
            "it": 
        <span>Il corso di laurea si è concentrato su lezioni teoriche e pratiche riguardanti i seguenti argomenti:
                <li>Tecniche avanzate di analisi e data mining</li>
                <li>Machine learning, Text mining, Gestione e analisi di segnali e di immagini</li>
                <li>Statistica bayesiana</li>
                <li>Processi stocastici</li>
                <li>Statistica spaziale</li>
                <li>Tecniche avanzate di analisi delle serie temporali</li>
                <li>Tecniche di simulazione</li>
        </span>
        
        },
        "110 cum Laude",
        {"en": "Object localization and extraction of structured information from complex images", "it": "Localizzazione di oggetti ed estrazione di informazioni strutturate da immagini complesse"},
        tesi_magistrale
    ),
    new TechEducation(
        "07.2017", 
        "09.2014", 
        {"en": "Bachelor degree, Statistics and economics sciences (University of Milan - Bicocca)", "it": "Laurea Triennale, Scienze Statische ed Economiche (Università degli Studi di Milano-Bicocca)"},
        {   "en": (
            <span>The degree focused on both theoretical and practical lessons about the following topics:
                    <li>Descriptive statistics and inference, sample theory, probability</li>
                    <li>Exploratory data analysis and Data Mining</li>
                    <li>Computer programming (programming languages: Python, R, SAS)</li>
                    <li>Economics, Econometrics, economic time series</li>
            </span>
        ), 
            "it": 
        <span>Il corso di laurea si è concentrato su lezioni teoriche e pratiche riguardanti i seguenti argomenti:
                <li>Statistica descrittiva e inferenza, teoria del campionamento, probabilità</li>
                <li>Analisi esplorativa dei dati e Data Mining</li>
                <li>Programmazione informatica (linguaggi di programmazione: Python, R, SAS)</li>
                <li>Economia, Econometria, serie temporali economiche</li>
        </span>
        
        },
        "110 cum Laude",
        {"en": "Skills identification in job offers using Word2Vec and GraphDB", "it": "Identificazione di skills in offerte di lavoro tramite Word2Vec e GraphDB"},
        tesi_triennale
    )
];

export default TechEducations;