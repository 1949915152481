import React from 'react';

const TechWorkExperienceComponent = ({ el, language }) => {
  return (
    <div className="tech-work-experience tech-subcategory">
    <div className='tech-timeline'></div>
    <div className='tech-timeline-point'></div>
      <div className='tech-subcategory-left'>
        <strong>{el.from} - {el.to}</strong> <br></br>
        <strong><a href={el.company_url} target="_blank" rel="noreferrer">{el.company}</a> ({el.get_translated_where(language)})</strong><br></br>
      </div>
      <div className='tech-subcategory-right'>
        <strong>{el.role}</strong> <br></br>
        <p>{el.get_translated_description(language)}</p>
      </div>
    </div>
  );
};

export default TechWorkExperienceComponent;
