import React from "react";
import { ReactComponent as CopyIcon } from "../domain/icons/copy-icon.svg";
import { AppContext } from "./context/AppContext";
import get_translated_content from "../domain/languages/get_translated_content";

class Share extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = { copied: false };
        this.copyUrl = this.copyUrl.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);

        this.ref = React.createRef(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.handleDocumentClick, true);
      }
    
      componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick, true);
      }
  
      handleDocumentClick(event) {
        if (!this.ref.current.contains(event.target)) {
          this.props.closeShare();
        }
      }

    copyUrl() {
        const url = window.location.origin;
        navigator.clipboard.writeText(url).then(() => {
          this.setState({ copied: true });
          setTimeout(() => {
            this.setState({ copied: false });
          }, 1000); // Clear copied message after 1 sec
        });
      }

    render() {
        const { language } = this.context;

        return (
        <div>
            <div className="background"></div>
            <div className="share" ref={this.ref}>
                <div className="closebutton font-face-forosans" onClick={this.props.closeShare}>x</div>
                <p>{get_translated_content(language, "share.title")}</p>
                <div className="share-url">
                <span>{window.location.origin}</span> 
                <span className="copy-button"><CopyIcon onClick={this.copyUrl} width={18} height={18}></CopyIcon></span>
                {this.state.copied && 
                    (<div className="copied">{get_translated_content(language, "copied")}</div>)}
                </div>
                
            </div>
        </div>)
    }
  }

export default Share;