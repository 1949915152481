// NewsDetail.js

import React from "react";
import { AppContext } from "../context/AppContext";

class NewsDetail extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.close = this.close.bind(this);

        this.ref = React.createRef(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.handleDocumentClick, true);
      }
    
      componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick, true);
      }
  
      handleDocumentClick(event) {
        if (!this.ref.current.contains(event.target)) {
          this.props.onClose();
        }
      }

    close() {
        this.props.onClose();
    }

    render() {
        const { language } = this.context;
        const { news_item } = this.props;

        return (
            <div>
                <div className="background"></div>
                <div className="news-detail" ref={this.ref}>
                <div className="closebutton font-face-forosans" onClick={this.close}>x</div>
                    <div className="news-card-date">{news_item.date}</div>
                    <div className="news-card-title">{news_item.get_translated_title(language)}</div>
                    <div className="news-card-description">{news_item.get_translated_description(language)}</div>
            </div>
            </div>
        );
    }
}

export default NewsDetail;
