export class SubCategory {
    title: string;
    link: string;
    title_translations: object;
    subtitle_translations: object;
    content_translations: object;
    
    constructor(title: string, link: string, title_translations: object, subtitle_translations: object, content_translations: object) {
        this.title = title;
        this.link = link;
        this.title_translations = title_translations;
        this.subtitle_translations = subtitle_translations;
        this.content_translations = content_translations;
    }

    get_translated_title(language: string) {
        if (!(language in this.title_translations)) language = "en";
        
        return this.title_translations[language];
    }

    get_translated_subtitle(language: string) {
        if (!(language in this.subtitle_translations)) language = "en";
        
        return this.subtitle_translations[language];
    }

    get_translated_content(language: string) {
        if (!(language in this.content_translations)) language = "en";
        
        return this.content_translations[language];
    }
}