import React, { useState, useContext } from "react";
import { SectionsRoutes } from "../domain/enums/SectionsRoutes.ts";
import { Link, useLocation } from "react-router-dom";
import { Sections } from "../domain/Sections.js";
import { AppContext } from "./context/AppContext.js";
import { ReactComponent as ArrowLeftIcon } from "../domain/icons/icons8-arrow-32-left.svg";
import { ReactComponent as ArrowRightIcon } from "../domain/icons/icons8-arrow-32-right.svg";


const FooterMobileNavigator = (props) => {
  const { language } = useContext(AppContext);
  let currentRoute = useLocation().pathname;

  // Remove trailing "/" if it exists
  if (currentRoute.endsWith("/") && currentRoute != "/") {
    currentRoute = currentRoute.slice(0, -1);
  }

  const sections = [];
    // Loop over the values of the enum
  for (const value of Object.values(SectionsRoutes)) {
    sections.push(value);
  }

  const currentIndex = sections.indexOf(currentRoute);

  const nextRoute = currentIndex < sections.length ? sections[currentIndex+1] : null;
  const previousRoute = currentIndex == 0 ? null : sections[currentIndex-1];

  return (
    <div className="mobile-navigator">
        <div className="mobile-navigator-arrow">
          {previousRoute != null && 
            <div className="mobile-navigator-arrow-internal-left button-arrow-footer">
              <div><ArrowLeftIcon className="arrow-icon-footer-left"/></div>
              <Link to={previousRoute}>
                {Sections.find((section) => section.route === previousRoute).get_translated_name(language)}
              </Link>
            </div>
          }
        </div>
        <div className="mobile-navigator-arrow">
        </div>
        <div className="mobile-navigator-arrow">
          {nextRoute != null && 
            <div className="mobile-navigator-arrow-internal-right button-arrow-footer">
              <Link to={nextRoute}>
                {Sections.find((section) => section.route === nextRoute).get_translated_name(language)}
              </Link>
              <div><ArrowRightIcon className="arrow-icon-footer-right"/></div>
            </div>
          }
        </div>
    </div>
  );
};

export default FooterMobileNavigator;
