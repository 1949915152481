import React from "react";
import Canvas from "../Canvas";
import { AppContext } from "../context/AppContext";
import { ReactComponent as ArrowLeftIcon } from "../../domain/icons/icons8-arrow-32-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../domain/icons/icons8-arrow-32-right.svg";
import withRouter from "../withRouter";
import { ArtGalleryCollections } from "../../domain/ArtGalleryCollections";
import { ArtGalleryItems } from "../../domain/ArtGalleryItems";

class GalleryCollectionDetail extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    const { params } = this.props.router;
    const selectedCollection = ArtGalleryCollections.find(coll => {return coll.id === params.mediaId});
    this.initializeView(selectedCollection);

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.closeDetail = this.closeDetail.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.goToNextMedia = this.goToNextMedia.bind(this);
    this.goToNextCollection = this.goToNextCollection.bind(this);
    this.goToPreviousMedia = this.goToPreviousMedia.bind(this);
    this.goToNextDetailGroup = this.goToNextDetailGroup.bind(this);
    this.goToPreviousCollection = this.goToPreviousCollection.bind(this);
    this.goToNextClick = this.goToNextClick.bind(this);
    this.handleDotClick = this.handleDotClick.bind(this);

    this.ref = React.createRef(this);
  }

  initializeView(selectedCollection) {
    const selectedMedia = selectedCollection.medias[0];

    let collections = ArtGalleryCollections;
    collections.sort((a, b) => a.year+a.id < b.year+b.id ? 1 : -1);
    this.otherCollections = collections.filter((coll) => coll.subcategory === selectedCollection.subcategory);

    let items = ArtGalleryItems;
    items.sort((a, b) => a.year+a.id < b.year+b.id ? 1 : -1);
    this.nextDetailGroup = items.filter((item) => item.subcategory === selectedCollection.subcategory);
    
    // the following variables are useful to handle interactions 
    const indexCollection = this.otherCollections.indexOf(selectedCollection);
    const hasNextCollection = indexCollection < this.otherCollections.length - 1;
    const hasPreviousCollection = indexCollection > 0;

    const hasNextDetailGroup = this.nextDetailGroup.length > 0;

    const state = {
      selectedCollection: selectedCollection,
      selectedMedia: selectedMedia,
      visibleItem: 0,

      touchstartX: null,
      touchendX: null,

      indexCollection: indexCollection,
      hasNextCollection: hasNextCollection,
      hasPreviousCollection: hasPreviousCollection,

      hasNextDetailGroup: hasNextDetailGroup,

      hasNextMedia: true,
      hasPreviousMedia: false,

      userIsHandlingVisibleItem: false
    };

    if (this.state)
      this.setState(state);
    else this.state = state;
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick, true);
    document.addEventListener("keydown", this.handleKeyDown, true);
    document.addEventListener("touchstart", this.handleTouchStart);
    document.addEventListener("touchend", this.handleTouchEnd);

    this.startInterval();
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
      document.removeEventListener("click", this.handleDocumentClick, true);
      document.removeEventListener("keydown", this.handleKeyDown, true);
      document.removeEventListener("touchstart", this.handleTouchStart);
      document.removeEventListener("touchend", this.handleTouchEnd);

      clearInterval(this.interval);
      document.body.style.overflow = 'unset';
  }

  handleTouchStart = (e) => {
    this.setState({ touchstartX: e.changedTouches[0].screenX });
  };
  
  handleTouchEnd = (e) => {
    this.setState({ touchendX: e.changedTouches[0].screenX });
    this.handleSwipe(this.state.touchstartX, this.state.touchendX);
  };

  startInterval() {
    this.interval = setInterval(this.changeVisibleItem, 4000);
  }

  handleDocumentClick(event) {
      if (!this.ref.current.contains(event.target)) {
        this.closeDetail();
      }
  }

  handleKeyDown(e) {
    this.setState({userIsHandlingVisibleItem: true});
    const { visibleItem, indexCollection } = this.state;
    if (visibleItem != null && indexCollection != null) {
      // arrow left/right button should select previous/next list element
      if (e.keyCode === 37) {
        // if it has a previous Media in this collection --> go to that media
        if (this.state.hasPreviousMedia) this.goToPreviousMedia();

        // if it has a previous Collection --> go to that collection
        else if (this.state.hasPreviousCollection) this.goToPreviousCollection();
      }
      else if (e.keyCode === 39) {
        // if it has a next Media in this collection --> go to that media
        if (this.state.hasNextMedia) this.goToNextMedia();
        
        // if it has a next Collection --> go to that collection
        else if (this.state.hasNextCollection) this.goToNextCollection();

        // if it has a next group of items --> go there
        else if (this.state.hasNextDetailGroup) this.goToNextDetailGroup();
      } 
    }
  }

  handleSwipe(touchstartX, touchendX) {
    const { indexCollection } = this.state;
    const swipeThreshold = 150; // Adjust this threshold as needed
  
    if (indexCollection != null) {
      const deltaX = touchendX - touchstartX;
  
      if (Math.abs(deltaX) >= swipeThreshold) {
      if (touchendX < touchstartX) {
        // if it has a next Media in this collection --> go to that media
        if (this.state.hasNextMedia) this.goToNextMedia();
        
        // if it has a next Collection --> go to that collection
        else if (this.state.hasNextCollection) this.goToNextCollection();

        // if it has a next group of items --> go there
        else if (this.state.hasNextDetailGroup) this.goToNextDetailGroup();
      }
      else if (touchendX > touchstartX) {
        // if it has a previous Media in this collection --> go to that media
        if (this.state.hasPreviousMedia) this.goToPreviousMedia();

        // if it has a previous Collection --> go to that collection
        else if (this.state.hasPreviousCollection) this.goToPreviousCollection();
      }
    }
  }
  }


  closeDetail() {
    const { navigate, location } = this.props.router;

    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const secondLastSlashIndex = location.pathname.lastIndexOf("/", lastSlashIndex - 1);

    navigate(location.pathname.substring(0, secondLastSlashIndex), 
    {
      state: {
        "scroll": false
      }
    });
  }

  changeVisibleItem = () => {
    if (!this.state.userIsHandlingVisibleItem) {
      const { medias } = this.state.selectedCollection;
      const newIndex = (this.state.visibleItem + 1) % medias.length;
      this.changeMedia(newIndex);
    }
  };

  goToPreviousMedia() {
    const newIndex = this.state.visibleItem - 1;
    this.changeMedia(newIndex);
  }

  goToNextMedia() {
    const newIndex = this.state.visibleItem + 1;
    this.changeMedia(newIndex);
  }

  changeMedia(newIndex) {
    const { medias } = this.state.selectedCollection;

    this.setState({
      selectedMedia: medias[newIndex],
      visibleItem: newIndex,
      hasNextMedia: newIndex < (medias.length - 1),
      hasPreviousMedia: newIndex > 0,
    });
  }

  goToPreviousCollection() {
    const { indexCollection } = this.state;
    const previousCollection = this.otherCollections[indexCollection - 1];
    this.changeCollection(previousCollection);
  }

  goToNextCollection() {
    const { indexCollection } = this.state;
    const nextCollection = this.otherCollections[indexCollection + 1];
    this.changeCollection(nextCollection);
  }

  changeCollection(collection) {
    const { navigate, location } = this.props.router;

    this.initializeView(collection);
    navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")) + "/" + collection.id);
  }

  goToNextDetailGroup() {
    const { navigate, location } = this.props.router;

    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const secondLastSlashIndex = location.pathname.lastIndexOf("/", lastSlashIndex - 1);

    const nextItem = this.nextDetailGroup[0];
    navigate(location.pathname.substring(0, secondLastSlashIndex) + "/" + nextItem.id, 
    {
      state: {
        "scroll": false
      }
    });
  }

  goToNextClick() {
    // if it has a next Collection --> go to that collection
    if (this.state.hasNextCollection) this.goToNextCollection();

    // if it has a next group of items --> go there
    else if (this.state.hasNextDetailGroup) this.goToNextDetailGroup();
  }

  handleDotClick = (e) => {
    this.changeMedia(e);
  }

  render() {
    const { language } = this.context;

    const { selectedMedia, selectedCollection } = this.state;
    
    const media = selectedMedia;
    const { size, year } = selectedCollection;
    const media_title=selectedCollection.get_translated_title(language);
    const media_description=selectedCollection.get_translated_description(language);
    const group=selectedCollection.get_translated_group(language);
    const medium=selectedCollection.get_translated_medium(language);

    return (
        <div>
          <div className="background"></div>
          <div className="galleryitemdetail" ref={this.ref}>
            <div  className="galleryitemdetail-header">
            <marquee className="galleryitem-title">{media_title}</marquee>
              <div></div>
              <div className="closebutton" onClick={this.closeDetail}>
                x
              </div>
            </div>

            <div className="galleryitemdetail-content">
              <div className="gallery-arrow">
                { this.state.hasPreviousCollection && <ArrowLeftIcon className="arrow-left-icon" onClick={this.goToPreviousCollection}/> }
              </div>
              
              <div className="gallerydetail-arrow-util">

                <div className="gallerydetail-content-image-container">
                  <div className="gallerydetail-content-media">
                    {typeof media === "string" && media.endsWith(".mp4") ? (
                      <video alt={media_title} controls src={media} type="video/mp4" />
                    ) : (
                      <img src={media} alt={media_title} />
                    )}
                  </div>

                  <div className="gallerycollection-dots">
                    {Array.from({ length: selectedCollection.medias.length }, (_, index) => (
                      <div className={`gallerycollection-dot ${index === this.state.visibleItem ? 'selected-dot' : ''}`} key={index}
                        onClick={ ()=> this.handleDotClick(index) }></div>
                    ))}
                  </div>
                </div>

                <div className="galleryitemdetail-collection-gallery">
                 {selectedCollection.medias.map((item, index) => (
                    typeof media === "string" && media.endsWith(".mp4") ? (
                      <video key={index} alt={media_title} controls src={item} type="video/mp4" 
                        className={`${index != this.state.visibleItem ? 'not-selected-media' : ''}`} 
                        onClick={ ()=> this.handleDotClick(index) }/>
                    ) : (
                      <img key={index} src={item} alt={media_title} 
                        className={`${index != this.state.visibleItem ? 'not-selected-media' : ''}`}
                        onClick={ ()=> this.handleDotClick(index) }/>
                    )
                  ))} 
                </div>

                <div className="galleryitemdetail-description">
                <div className="galleryitem-title-1"><strong>{media_title}</strong></div><br></br>
                   {/* <p><strong>{group}</strong></p>  */}
                  <div className="galleryitem-subtitle"><strong>{year}</strong></div>
                  <div className="galleryitem-subtitle"><strong>{medium}</strong></div>
                  <div className="galleryitem-subtitle"><strong>{size}</strong></div>
                  <div className="galleryitem-paragraph">{media_description}</div>
                </div>
              </div>

              <div className="gallery-arrow">
                {(this.state.hasNextCollection || this.state.hasNextDetailGroup) 
                  && <ArrowRightIcon className="arrow-right-icon" onClick={this.goToNextClick}/> }
              </div>

            </div>
          </div>
        </div>
      )
  }
}

export default withRouter(GalleryCollectionDetail);

// todo: better code