import React from "react";
import { DEFAULT_CATEGORY } from "../../domain/enums/DefaultCategory";
import { AppContext } from "../context/AppContext";
import GalleryItem from "./GalleryItem";
import GalleryItemDetail from "./GalleryItemDetail";
import { Link } from "react-router-dom";
import GalleryCollection from "./GalleryCollection";
import get_translated_content from "../../domain/languages/get_translated_content";

class Gallery extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    let items = this.props.items;
    items.sort((a, b) => a.year+a.id < b.year+b.id ? 1 : -1);
    if (this.props.subcategory !== DEFAULT_CATEGORY)
      items = items.filter((item) => item.subcategory === this.props.subcategory);

    let collections = this.props.collections;
    collections.sort((a, b) => a.year+a.id < b.year+b.id ? 1 : -1);
    if (this.props.subcategory !== DEFAULT_CATEGORY)
      collections = collections.filter((coll) => coll.subcategory === this.props.subcategory);

    this.state = {
      showDetail: false,
      items: items,
      collections: collections
    };

  }

  render() {
    const { language } = this.context;

    let subcategory, thumbnail_size;

    thumbnail_size = this.props.thumbnail_size;
    subcategory = this.props.subcategory;

    return (
      <>
      <div className="gallery font-face-forosans">
        {this.state.collections.length > 0 && 
          <div> 
            <div className="gallery-subtitle">
              {get_translated_content(language, "gallery.subtitle.collections")}
            </div>
            <div className="subgallery">
            {this.state.collections.map((collection, index) => (
              <GalleryCollection
                key={index}
                collection={collection}
                thumbnail_size={thumbnail_size}
              />
            ))}
            </div>
          </div>
        }

        {this.state.items.length > 0 &&
          <div>
            <div className="gallery-subtitle">
              {get_translated_content(language, "gallery.subtitle.items")}
            </div>
            <div className="subgallery">
            {this.state.items.map((item, index) => (
              <Link key={index} to={`/art/${item.id}`}>
              <GalleryItem
                key={index}
                item={item}
                thumbnail_size={thumbnail_size}
              /></Link>
            ))}
            </div>
          </div>
        }

      </div>
      </>
    );
  }
}

export default Gallery;
