export function calculatePixelWidth(vwValue) {
    const viewportWidth = window.screen.width;
    const pixelWidth = (vwValue / 100) * viewportWidth;
    return pixelWidth;
}
  

export function calculatePixelHeight(vhValue) {
    const viewportHeight = window.screen.height;
    const pixelHeight = (vhValue / 100) * viewportHeight;
    return pixelHeight;
}
  