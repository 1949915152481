import React, { createContext, useState, useEffect } from 'react';
import { ArtGalleryItems } from '../../domain/ArtGalleryItems';
import { calculatePixelWidth } from '../../utils/compute_vw';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  // language 
  const [language, setLanguage] = useState('');

  // device
  const [isMobile, setIsMobile] = useState(false);

  // media detail
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    //TEST: da rimuovere
    const media = ArtGalleryItems[0];
    setSelectedMedia(media)

    // Check user's location and set the default language accordingly
    const userLanguage = navigator.language.includes('it') ? 'it' : 'en';
    setLanguage(userLanguage);

    const handleResize = () => {
      const isMobileView = Math.min(window.screen.width, window.screen.height) <= 820 || window.innerWidth <= 820; // Adjust the breakpoint as needed
      setIsMobile(isMobileView);

    };

    handleResize(); // Initial check on component mount

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const changeSelectedMedia = (newSelectedMedia) => {
    setSelectedMedia(newSelectedMedia);
  };

  return (
    <AppContext.Provider 
      value={
        { language, changeLanguage, 
        isMobile,
        selectedMedia, changeSelectedMedia }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
