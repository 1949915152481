export class TechProject {
    title: object;
    description: object;
    link: string;
    collaborators: string;
    when: string;
    
    constructor(title: object, description: object, link: string, collaborators: string, when: string) {
        this.title = title;
        this.description = description;
        this.link = link;
        this.collaborators = collaborators;
        this.when = when;
    }

    get_translated_description(language: string) {
        if (!(language in this.description)) language = "en";
        
        return this.description[language];
    }

    get_translated_title(language: string) {
        if (this.title == undefined) return;

        if (!(language in this.title)) language = "en";
        
        return this.title[language];
    }
}