import React from "react";
import { Link } from "react-router-dom";

class PageContent extends React.Component {
    render() {
      let img;
      if (this.props.img == null) img = (<div className="contentimage">Image Placeholder</div>);
      else img = <div className="contentimage">{this.props.img}</div>;

      return (
        <div className="pagecontent" id={this.props.id}>
            {img}
            <div className="content">
                {this.props.content}
            </div>
        </div>
      );
    }
  }

export default PageContent;