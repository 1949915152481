export class TechWorkExperience {
    from: string;
    to: string;
    role: string;
    company: string;
    company_url: string;
    where: object;
    description: object;
    
    constructor(from: string, to: string, role: string, company: string, company_url: string, where: object, description: object) {
        this.from = from;
        this.to = to;
        this.role = role;
        this.company = company;
        this.company_url = company_url;
        this.where = where;
        this.description = description;
    }

    get_translated_where(language: string) {
        if (!(language in this.where)) language = "en";
        
        return this.where[language];
    }

    get_translated_description(language: string) {
        if (!(language in this.description)) language = "en";
        
        return this.description[language];
    }
}