import { SubCategory } from "./models/SubCategory.ts";
import { TechSubCategoryTitle } from "./enums/TechSubCategoryTitle.ts";
import TechEducations from "./TechEducations.js";
import TechWorkExperiences from "./TechWorkExperiences.js";
import TechCertifications from "./TechCertifications.js";
import TechProjects from "./TechProjects.js";
import TechEducationComponent from "../components/tech_subcomponents/TechEducationComponent.js";
import TechWorkExperienceComponent from "../components/tech_subcomponents/TechWorkExperienceComponent.js";
import TechProjectComponent from "../components/tech_subcomponents/TechProjectComponent.js";
import cv from "./pdfs/CV - english.pdf";
import cv_it from "./pdfs/CV - english.pdf";
import get_translated_content from "./languages/get_translated_content.js";
import { SectionsRoutes } from "./enums/SectionsRoutes.ts";

const TechSubCategories = [
    new SubCategory(TechSubCategoryTitle.ALL, SectionsRoutes.TECH, {"en": "Tech", "it": "Tech"}, 
      {"en": "Tech", "it": "Tech"}, 
      {"en": 
      (<div>
        <button className="cv-button">
          <a href={cv} target="_blank" rel="noreferrer">{get_translated_content("en", "view.cv")}</a>
        </button>
        I'm a highly skilled <strong>Freelance Software and Machine Learning Developer</strong>. Throughout my professional journey, I have prioritized developing a <strong>horizontal, robust and versatile skill set</strong> that enables me to contribute effectively to projects across all stages. From defining requirements and gathering data to handling data streams, designing databases, training, validating and monitoring models, deploying them, and developing websites and applications, I strive to have a <strong>holistic understanding</strong>. <br></br><br></br>
        Still, my core expertise lies in <strong>data analysis and machine learning</strong>, and I made sure to continuously keep up with new technologies and advancements in the field, such as Generative AI and LLMs. I approach learning with enthusiasm and thrive on gaining new knowledge and utilizing new tools and techniques. My technical skills include expertise of <strong>cloud services (Azure, AWS, Google Cloud)</strong>; high proficiency in programming languages such as <strong>Python, JavaScript, and C#</strong>; <strong>web applications, ML-based applications development, data analysis and visualization, machine learning and deep learning</strong>. I'm also currently learning creative coding (canvas-sketch, three.js) and Touchdesigner and having fun with it.
      </div>), 
      "it": 
      (<div>
        <button className="cv-button">
          <a href={cv_it} target="_blank" rel="noreferrer">{get_translated_content("it", "view.cv")}</a>
        </button>
        Sono una <strong>Sviluppatrice Freelance di Software e Machine Learning altamente qualificata</strong>. Lungo il mio percorso professionale, ho dato la massima priorità allo sviluppo di un insieme di competenze <strong>orizzontali, robuste e versatili</strong> che mi consentono di contribuire in modo efficace a progetti in tutte le fasi. Dalla definizione dei requisiti e la raccolta dei dati alla gestione dei flussi di dati, alla progettazione di database, all'allenamento, validazione e monitoraggio dei modelli, al loro rilascio e allo sviluppo di siti web e applicazioni, mi sforzo di avere una <strong>comprensione olistica</strong>. <br></br><br></br>
        La mia conoscenza fondamentale rimane l'<strong>analisi dei dati e il machine learning</strong>, e mi sono sempre assicurata di tenermi aggiornata sulle nuove tecnologie e gli sviluppi del campo, come AI Generativa e LLMs. Mi entusiasmano l'acquisire nuove conoscenze e l'imparare a utilizzare nuovi strumenti e tecniche. Le mie competenze tecniche includono una vasta esperienza nei <strong>servizi cloud (Azure, AWS, Google Cloud)</strong>; una grande competenza nei linguaggi di programmazione come <strong>Python, JavaScript e C#</strong>; <strong>lo sviluppo di applicazioni web e applicazioni ML-based, l'analisi e la visualizzazione dei dati, il machine learning e il deep learning</strong>. Attualmente sto anche cimentandomi con il creative coding (canvas-sketch, three.js) e Touchdesigner.
      </div>)}),
    new SubCategory(TechSubCategoryTitle.TECHNICAL_SKILLS, "/tech/work", {"en": "Technical Skills", "es": "Trabajo", "it": "Skill Tecniche"},
      {"en": "Technical Skills", "it": "Skill Tecniche"}, 
      {"en": (
        <ul>
        <li>Extensive <strong>git</strong> knowledge</li>
        <li>Highly skilled in <strong>Data Analysis and Visualization</strong>, <strong>Machine Learning</strong>, and <strong>Deep Learning</strong> (including predictive modeling, text mining, computer vision, recommender systems)</li>
        <li>Strong expertise in <strong>Natural Language Processing (NLP), Recommender Systems, Computer Vision, Generative AI and Large Language Models (LLMs)</strong></li>
        <li>Broad experience in:
          <ul>
            <li><strong>Web application development</strong> (including data intensive webapps)</li>
            <li><strong>Website development</strong></li>
            <li>Development of <strong>REST APIs</strong>, <strong>websockets</strong>, <strong>webhooks</strong>, handling <strong>IoT data streams</strong></li>
          </ul>
        </li>
        <li>Programming languages:
          <ul>
            <li><strong>High proficiency</strong>: <strong>Python</strong>, <strong>C#</strong>, <strong>Javascript</strong>, <strong>HTML</strong>, <strong>CSS</strong>, <strong>R</strong></li>
            <li><strong>Good knowledge</strong>: <strong>Swift</strong>, <strong>SAS 9</strong></li>
          </ul>
        </li>
        <li>Databases:
          <ul>
            <li><strong>High proficiency</strong>: <strong>SQL</strong>, <strong>MongoDB</strong>, <strong>Graph databases</strong> (for example Neo4j)</li>
          </ul>
        </li>
        <li>Broad working experience with cloud services:
          <ul>
            <li><strong>Google Cloud</strong> tools (<strong>BigQuery</strong>, <strong>Data Flow</strong>, <strong>Data Proc</strong>, <strong>PubSub</strong>, <strong>Cloud Storage</strong>, <strong>AI Platform</strong>, <strong>AI APIs</strong>)</li>
            <li><strong>AWS</strong> tools (<strong>Lambda</strong>, <strong>SageMaker</strong>, <strong>S3</strong>, <strong>Redshift</strong>)</li>
            <li><strong>Microsoft Azure</strong> tools (<strong>Functions</strong>, <strong>SQL database</strong>, <strong>BlobStorage</strong> and <strong>TableStorage</strong>, <strong>ServiceR</strong>, <strong>EventHub</strong>)</li>
          </ul>
        </li>
        <li>Good knowledge of the following Python libraries: <strong>Scikit-Learn</strong>, <strong>Pandas</strong>, <strong>NumPy</strong>, <strong>TensorFlow</strong>, <strong>Keras</strong>, <strong>NLTK</strong>, <strong>OpenCV</strong>, <strong>Matplotlib</strong>, <strong>PySpark</strong>, <strong>Pytorch</strong>, <strong>fastAPI</strong>, <strong>Django</strong>, <strong>pyCaret</strong></li>
        <li>Good knowledge of <strong>React</strong></li>
        <li>Miscellaneous:
          <ul>
            <li><strong>Nvidia Omniverse extensions development</strong></li>
            <li>Experience with <strong>Apache Beam</strong></li>
            <li>Good knowledge of <strong>Docker</strong></li>
            <li>Working experience with <strong>Terraform</strong></li>
            <li>Tried using <strong>canvas-sketch</strong>, <strong>three.js</strong> javascript libraries for creative coding experiments</li>
          </ul>
        </li>
      </ul>
      ), 
    "it": (
      <ul>
         <li>Vasta conoscenza di <strong>git</strong></li>
          <li>Elevata competenza in <strong>Analisi e Visualizzazione dei Dati</strong>, <strong>Machine Learning</strong> e <strong>Deep Learning</strong> (inclusi modelli predittivi, text mining, computer vision, sistemi di raccomandazione)</li>
          <li>Elevata competenza in <strong>Natural Language Processing (NLP), Sistemi di Raccomandazione, Computer Vision, AI Generativa e Large Language Models (LLMs)</strong></li>
          <li>Ampia esperienza in:
              <ul>
              <li><strong>Sviluppo di applicazioni web</strong> (incluse web app con carichi di dati intensivi)</li>
              <li><strong>Sviluppo di siti web</strong></li>
              <li>Sviluppo di <strong>API REST</strong>, <strong>websockets</strong>, <strong>webhooks</strong>, gestione di <strong>flussi dati IoT</strong></li>
              </ul>
          </li>
          <li>Linguaggi di programmazione:
              <ul>
              <li><strong>Elevata competenza</strong>: <strong>Python</strong>, <strong>C#</strong>, <strong>Javascript</strong>, <strong>HTML</strong>, <strong>CSS</strong>, <strong>R</strong></li>
              <li><strong>Buona conoscenza</strong>: <strong>Swift</strong>, <strong>SAS 9</strong></li>
              </ul>
          </li>
          <li>Basi di dati:
              <ul>
              <li><strong>Alta competenza</strong>: <strong>SQL</strong>, <strong>MongoDB</strong>, <strong>Graph databases</strong> (ad esempio Neo4j)</li>
              </ul>
          </li>
          <li>Ampia esperienza lavorativa con servizi cloud:
              <ul>
              <li>Strumenti <strong>Google Cloud</strong> (<strong>BigQuery</strong>, <strong>Data Flow</strong>, <strong>Data Proc</strong>, <strong>PubSub</strong>, <strong>Cloud Storage</strong>, <strong>AI Platform</strong>, <strong>AI APIs</strong>)</li>
              <li>Strumenti <strong>AWS</strong> (<strong>Lambda</strong>, <strong>SageMaker</strong>, <strong>S3</strong>, <strong>Redshift</strong>)</li>
              <li>Strumenti <strong>Microsoft Azure</strong> (<strong>Functions</strong>, <strong>SQL database</strong>, <strong>BlobStorage</strong> and <strong>TableStorage</strong>, <strong>ServiceR</strong>, <strong>EventHub</strong>)</li>
              </ul>
          </li>
          <li>Buona conoscenza delle seguenti librerie <strong>Python</strong>: <strong>Scikit-Learn</strong>, <strong>Pandas</strong>, <strong>NumPy</strong>, <strong>TensorFlow</strong>, <strong>Keras</strong>, <strong>NLTK</strong>, <strong>OpenCV</strong>, <strong>Matplotlib</strong>, <strong>PySpark</strong>, <strong>Pytorch</strong>, <strong>fastAPI</strong>, <strong>Django</strong>, <strong>pyCaret</strong></li>
          <li>Buona conoscenza di <strong>React</strong></li>
          <li>Varie:
              <ul>
              <li><strong>Sviluppo di estensioni Nvidia Omniverse</strong></li>
              <li>Esperienza con <strong>Apache Beam</strong></li>
              <li>Buona conoscenza di <strong>Docker</strong></li>
              <li>Esperienza lavorativa con <strong>Terraform</strong></li>
              <li>Ustilizzo delle librerie javascript <strong>canvas-sketch</strong> e <strong>three.js</strong> per esperimenti di creative coding</li>
              </ul>
          </li>
      </ul>)}),
    new SubCategory(TechSubCategoryTitle.WORK_EXPERIENCE, "/tech/workexperience", {"en": "Work Experience", "it": "Lavoro"},
      {"en": "Work Experience", "it": "Esperienze Lavorative"},
      {"en": (
        TechWorkExperiences.map((el, index) => (
          <TechWorkExperienceComponent key={index} el={el} language="en" />
          ))
        ), 
        "it": (
          TechWorkExperiences.map((el, index) => (
            <TechWorkExperienceComponent key={index} el={el} language="it" />
            ))
        )}),
      new SubCategory(TechSubCategoryTitle.EDUCATION, "/tech/education", {"en": "Education", "it": "Formazione"},
      {"en": "Education", "it": "Formazione"}, 
      {"en": (
          TechEducations.map((el, index) => (
            <TechEducationComponent key={index} el={el} language="en" />
            ))
      ), 
      "it": (
        TechEducations.map((el, index) => (
          <TechEducationComponent key={index} el={el} language="it" />
          ))
      )}),
    new SubCategory(TechSubCategoryTitle.CERTIFICATION, "/tech/certification", {"en": "Certification", "it": "Certificazioni"}, 
      {"en": "Certification", "it": "Certificazioni"}, 
      {"en": (
        <div className="tech-certification">
        {TechCertifications.map((el, index) => (
          <img height={100} className="tech-cert-img" src={el.image} key={index} alt={el.title}></img>
        ))}
        {TechCertifications.map((el, index) => (
          <li key={index}><a href={el.link}>{el.title}</a></li>
        ))}
        </div>
      )}),
    new SubCategory(TechSubCategoryTitle.PROJECTS, "/tech/projects", {"en": "Projects", "it": "Progetti"}, 
      {"en": "Projects", "it": "Progetti"}, 
      {"en": (
        TechProjects.map((el, index) => (
          <TechProjectComponent key={index} el={el} language="en" />
          ))
        ), 
        "it":  (
          TechProjects.map((el, index) => (
            <TechProjectComponent key={index} el={el} language="it" />
            ))
          )})
];

export default TechSubCategories;