import React from "react";
import Canvas from "../Canvas";
import { AppContext } from "../context/AppContext";
import { ReactComponent as ArrowLeftIcon } from "../../domain/icons/icons8-arrow-32-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../domain/icons/icons8-arrow-32-right.svg";
import withRouter from "../withRouter";
import { ArtGalleryItems } from "../../domain/ArtGalleryItems";
import { ArtGalleryCollections } from "../../domain/ArtGalleryCollections";

class GalleryItemDetail extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    const { params } = this.props.router;

    const selectedMedia = ArtGalleryItems.find(item => {return item.id === params.mediaId});

    let items = ArtGalleryItems;
    items.sort((a, b) => a.year+a.id < b.year+b.id ? 1 : -1);
    this.otherMedias = items.filter((item) => item.subcategory === selectedMedia.subcategory);

    let collections = ArtGalleryCollections;
    collections.sort((a, b) => a.year+a.id < b.year+b.id ? 1 : -1);
    this.previousDetailGroup = collections.filter((coll) => coll.subcategory === selectedMedia.subcategory);
    
    const index = this.otherMedias.indexOf(selectedMedia);
    const hasNext = index < this.otherMedias.length - 1;
    const hasPrevious = index > 0;

    const hasPreviousDetailGroup = this.previousDetailGroup.length > 0;

    this.state = {
        touchstartX: null,
        touchendX: null,
        selectedMedia: selectedMedia,
        index: index,
        hasNext: hasNext,
        hasPrevious: hasPrevious,
        hasPreviousDetailGroup: hasPreviousDetailGroup
    }

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.closeDetail = this.closeDetail.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.goToNext = this.goToNext.bind(this);
    this.goToPrevious = this.goToPrevious.bind(this);

    this.ref = React.createRef(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick, true);
    document.addEventListener("keydown", this.handleKeyDown, true);
    document.addEventListener("touchstart", this.handleTouchStart);
    document.addEventListener("touchend", this.handleTouchEnd);

    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick, true);
    document.removeEventListener("keydown", this.handleKeyDown, true);
    document.removeEventListener("touchstart", this.handleTouchStart);
    document.removeEventListener("touchend", this.handleTouchEnd);

    document.body.style.overflow = 'unset';
  }

  handleTouchStart = (e) => {
    this.setState({ touchstartX: e.changedTouches[0].screenX });
  };
  
  handleTouchEnd = (e) => {
    this.setState({ touchendX: e.changedTouches[0].screenX });
    this.handleSwipe(this.state.touchstartX, this.state.touchendX);
  };

  handleDocumentClick(event) {
      if (!this.ref.current.contains(event.target)) {
        this.closeDetail();
      }
  }

  handleKeyDown(e) {
    const { index } = this.state;
    if (index != null) {
      // arrow left/right button should select previous/next list element
      if (e.keyCode === 37 && (this.state.hasPrevious || this.state.hasPreviousDetailGroup)) this.goToPrevious();
      else if (e.keyCode === 39 && this.state.hasNext) this.goToNext();
    }
  }

  handleSwipe(touchstartX, touchendX) {
    const { index } = this.state;
    const swipeThreshold = 150; // Adjust this threshold as needed
  
    if (index != null) {
      const deltaX = touchendX - touchstartX;
  
      if (Math.abs(deltaX) >= swipeThreshold) {
        if (touchendX > touchstartX && (this.state.hasPrevious || this.state.hasPreviousDetailGroup)) {
          this.goToPrevious();
        } else if (touchendX < touchstartX && this.state.hasNext) {
          this.goToNext();
        }
      }
    }
  }

  closeDetail() {
    const { navigate, location } = this.props.router;
    navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")), 
    {
      state: {
        "scroll": false
      }
    });
  }

  goToPrevious() {
    const { navigate, location } = this.props.router;

    if (this.state.hasPrevious) {
      const { index } = this.state;

      const previous = this.otherMedias[index - 1]
      this.setState({ selectedMedia: previous, index: index - 1, hasNext: true, hasPrevious: index - 1 > 0 });
      navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/"))+ "/" + previous.id);
    }
    
    else if (this.state.hasPreviousDetailGroup) {
      const previousCollection = this.previousDetailGroup[this.previousDetailGroup.length - 1];
      navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/"))+ "/collections/" + previousCollection.id, 
      {
        state: {
          "scroll": false
        }
      });
    }
  }

  goToNext() {
    const { navigate, location } = this.props.router;

    const { index } = this.state;

    const next = this.otherMedias[index + 1]
    this.setState({ selectedMedia: next, index: index + 1, hasNext: index + 1 < this.otherMedias.length - 1, hasPrevious: true });
    navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/"))+ "/" + next.id);
  }

  render() {
    const { language } = this.context;

    const { selectedMedia } = this.state;
    const { media, size, year } = selectedMedia;
    const media_title=selectedMedia.get_translated_title(language);
    const media_description=selectedMedia.get_translated_description(language);
    const group=selectedMedia.get_translated_group(language);
    const medium=selectedMedia.get_translated_medium(language);

    return (
      <div>
          <div className="background"></div>
          <div className="galleryitemdetail" ref={this.ref}>
            <div  className="galleryitemdetail-header">
            <marquee className="galleryitem-title">{media_title}</marquee>
              <div></div>
              <div className="closebutton" onClick={this.closeDetail}>
                x
              </div>
            </div>

            <div className="galleryitemdetail-content">
              <div className="gallery-arrow">
                { (this.state.hasPrevious || this.state.hasPreviousDetailGroup ) 
                  && <ArrowLeftIcon className="arrow-left-icon" onClick={this.goToPrevious}/> }
              </div>
              
              <div className="gallerydetail-arrow-util">

                <div className="gallerydetail-content-image-container">
                  <div className="gallerydetail-content-media">
                    {typeof media === "string" && media.endsWith(".mp4") ? (
                      <video alt={media_title} controls src={media} type="video/mp4" />
                    ) : (
                      <img src={media} alt={media_title} />
                    )}
                  </div>

                  <div className="gallerycollection-dots">
                    
                  </div>
                </div>

                <div className="galleryitemdetail-collection-gallery">
                 
                </div>

                <div className="galleryitemdetail-description">
                <div className="galleryitem-title-1"><strong>{media_title}</strong></div><br></br>
                   {/* <p><strong>{group}</strong></p>  */}
                  <div className="galleryitem-subtitle"><strong>{year}</strong></div>
                  <div className="galleryitem-subtitle"><strong>{medium}</strong></div>
                  <div className="galleryitem-subtitle"><strong>{size}</strong></div>
                  <div className="galleryitem-paragraph">{media_description}</div>
                </div>
              </div>

              <div className="gallery-arrow-right gallery-arrow">
                { this.state.hasNext && <ArrowRightIcon className="arrow-right-icon" onClick={this.goToNext}/> }
              </div>

            </div>
          </div>

          {/* <div className="background"></div>
          <div className="galleryitemdetail" ref={this.ref}>
          <div  className="galleryitemdetail-header">
          <marquee className="galleryitem-title">{media_title}</marquee>
            <div></div>
            <div className="closebutton" onClick={this.closeDetail}>
              x
            </div>
          </div>
            
            <div className="gallery-arrow-left galleryitemdetail-content">
            <div className="gallery-arrow">
              { (this.state.hasPrevious || this.state.hasPreviousDetailGroup ) 
                && <ArrowLeftIcon className="arrow-left-icon" onClick={this.goToPrevious}/> }
            </div>
            <div className="gallerydetail-arrow-util">
              <div style={imgContainerSize}>
                  {typeof media === "string" && media.endsWith(".mp4") ? (
                    <video alt={media_title} autoPlay muted loop controls playsInline>
                      <source src={media} type="video/mp4" style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}/>
                    </video>
                  ) : typeof media === "string" && media === "canvas" ? (
                    <div style={{
                      width: `${media_size}px`,
                      height: `${media_size}px`
                    }}><Canvas draw={selectedMedia.draw} animate={selectedMedia.animate} width={media_size} height={media_size}/></div>
                  ) : (
                    <img src={media} alt={media_title} style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}/>
                  )}
                </div>
              <div className="galleryitemdetail-description">
                  <div className="galleryitem-title-1"><strong>{media_title}</strong></div><br></br>
                  {/* <p><strong>{group}</strong></p> 
                  <div className="galleryitem-subtitle"><strong>{year}</strong></div>
                  <div className="galleryitem-subtitle"><strong>{medium}</strong></div>
                  <div className="galleryitem-subtitle"><strong>{size}</strong></div>
                  <div className="galleryitem-paragraph">{media_description}</div>
              </div>
            </div>
            <div className="gallery-arrow-right gallery-arrow">
              { this.state.hasNext && <ArrowRightIcon className="arrow-right-icon" onClick={this.goToNext}/> }
            </div>
          </div>
          </div> */}
        </div>
        
          
      )
  }
}

export default withRouter(GalleryItemDetail);