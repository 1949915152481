import React from "react";
import { AppContext } from "../context/AppContext";
import Canvas from "../Canvas";
import { Link } from "react-router-dom";

class GalleryCollection extends React.Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = { isLoading: true, visibleItem: 0 };

      this.handleDotClick = this.handleDotClick.bind(this);
    }

    componentDidMount() {
        this.loadMedia();
        this.startInterval();
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }
  
    startInterval() {
      this.interval = setInterval(this.changeVisibleItem, 4000);
    }

    changeVisibleItem = () => {
      const { medias } = this.props.collection;
      this.setState((prevState) => ({
        visibleItem: (prevState.visibleItem + 1) % medias.length,
      }));
    };

    handleDotClick = (e) => {
      this.setState({visibleItem: e});
      clearInterval(this.interval);
      this.startInterval();
    }

    loadMedia() {
      const { medias, medias_thumbnails } = this.props.collection;
      const media = medias[0];
      const media_thumbnail = medias_thumbnails[0];

      if (media === "canvas") this.setState({ isLoading: false });

      if (typeof media_thumbnail === "string" && media.endsWith(".mp4") ) {
        this.setState({ isLoading: false });
      } 

      const image = new Image();
  
      image.onload = () => {
        this.setState({ isLoading: false });
      };
  
      image.src = media_thumbnail || media;
    }
  
    render() {
      const { isMobile, language } = this.context;

      const { thumbnail_size } = this.props;
      const { medias_thumbnails, medias } = this.props.collection;

      const media = medias[this.state.visibleItem];
      const media_thumbnail = medias_thumbnails[this.state.visibleItem];

      const media_title=this.props.collection.get_translated_title(language);
    
      return (
        <>
          <div className="galleryitem"
            style={{
              width: `${thumbnail_size}px`,
              height: `${thumbnail_size}px`
            }} >
            {this.state.isLoading && !isMobile ? (
              // Show loading animation if isLoading is true
              <div
                className="loading-background"
                style={{
                  width: `${thumbnail_size}px`,
                  height: `${thumbnail_size}px`
                }}
              >
                <div className="center-loading">
                  <div className="lds-dual-ring"></div>
                </div>
              </div>
            ) : (
              <div>
                <Link to={`/art/collections/${this.props.collection.id}`}>
                {typeof media_thumbnail === "string" && media.endsWith(".mp4") ? (
                  <video width={thumbnail_size} height={thumbnail_size} controls className="galleryitem-media">
                    <source src={media_thumbnail} type="video/mp4" />
                  </video>
                ) : typeof media_thumbnail === "string" && media === "canvas" ? (
                  <div 
                    style={{
                      width: `${thumbnail_size}px`,
                      height: `${thumbnail_size}px`
                    }} 
                  className="galleryitem-media"><Canvas draw={this.props.draw} animate={this.props.animate} width={thumbnail_size} height={thumbnail_size}/></div>
                ) : (
                  <img src={media_thumbnail} alt={media_title} width={thumbnail_size} height={thumbnail_size} className="galleryitem-media" />
                )}
                </Link>

                <div className="gallerycollection-dots">
                {Array.from({ length: medias.length }, (_, index) => (
                  <div className={`gallerycollection-dot ${index === this.state.visibleItem ? 'selected-dot' : ''}`} key={index} 
                    onClick={ ()=> this.handleDotClick(index) }></div>
                ))}
                </div>
                
              </div>
            )}
          </div>
        </>
      );
    }
  }

export default GalleryCollection;