import React from "react";
import { AppContext } from "../components/context/AppContext";
import get_translated_content from "../domain/languages/get_translated_content";

class Writing extends React.Component {
    static contextType = AppContext;

    render() {
      const { language } = this.context;
      const { isGlitchActive } = this.props;

      return (
        <div className={`writing ${isGlitchActive ? 'glitch' : ''}`} id="writing">
          <p className="font-face-sometypemono page-title">
            {get_translated_content(language, "writing.title")}
          </p>
          <p className="font-face-forosans page-paragraph">
            {get_translated_content(language, "writing.content")}
          </p>
        </div>
      );
    }
  }

export default Writing;