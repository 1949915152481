import React from "react";
import PageContent  from "../components/PageContent";
import { AppContext } from "../components/context/AppContext";
import get_translated_content from "../domain/languages/get_translated_content";
import Canvas from "../components/Canvas";
import drawRandomImages from "../domain/canvas/AboutMeCanva";
import { calculatePixelWidth } from "../utils/compute_vw";

class About extends React.Component {
  static contextType = AppContext;

    render() {
      const { language } = this.context;
      const { isMobile, isGlitchActive } = this.props;

      let canvaDim;
      if (isMobile) canvaDim = calculatePixelWidth(80);
      else canvaDim = 400;
      const img = <Canvas draw={drawRandomImages} animate={true} width={canvaDim} height={canvaDim}/>;

      let content = (
        <div className={`about ${isGlitchActive ? 'glitch' : ''}`}>
          <div className="font-face-sometypemono page-title">{get_translated_content(language, "about.title")}</div>
          <div className="font-face-forosans page-paragraph">{get_translated_content(language, "about.content")}</div>
        </div>
      );

      return <PageContent id="about" content={content} img={img}/>;
    }
  }

export default About;